import { useEffect, useState } from "react";

import { initialPagination } from "src/services/api";
import { Pagination } from "src/services/api/types";
import { getUserGroups, UserGroup } from "src/services/userGroups";
import { ReactTable } from "src/ui/components/Table";

import { userGroupColumns } from "./consts";

const UserGroupsPage: React.FC = () => {
  const [userGroups, setUserGroups] = useState<Pagination<UserGroup>>(
    initialPagination
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchUserGroups();
  }, []);

  const fetchUserGroups = async () => {
    setIsLoading(true);
    const res = await getUserGroups();
    setUserGroups(res);
    setIsLoading(false);
  };

  return (
    <ReactTable
      columns={userGroupColumns}
      data={userGroups.data}
      onPaginationChange={fetchUserGroups}
      isLoading={isLoading}
      totalPages={userGroups.meta.total}
    />
  );
};

export default UserGroupsPage;
