/* eslint-disable no-multi-str */

import { memo, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

import { MediaManager } from "../MediaManager";
import { API_URL } from "src/services/api";

const TinyMce = ({ label, hasError, error, ...otherProps }) => {
  const [showMediaManager, setShowMediaManager] = useState(false);
  const [fileCallback, setFileCallback] = useState();

  const handleMediaSelect = (file) => {
    // fileCallback(file, { title: "Neki title", text: "neki text" });

    setShowMediaManager(false);
    fileCallback(`${API_URL}/storage/${file}`);
  };

  return (
    <>
      <div className="form-group">
        {label && <label htmlFor={otherProps.id}>{label}</label>}
        <Editor
          apiKey="fddu2wqd0qq1o4ihmq63w5a9itp92guhn8hwmuqwyih2b1vk"
          init={{
            file_picker_types: "image file",
            file_picker_callback: function (cb, val) {
              setShowMediaManager(true);

              setFileCallback(() => cb);
            },
            autoresize_on_init: true,
            menubar: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor autoresize",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code wordcount help hr nonbreaking pagebreak emoticons template codesample imagetools directionality visualchars fontsizeselect",
              //importcss - collision with formats
            ],
            // props for importing custom css for visual styling
            content_css: [
              "/assets/css/tinymce_custom.css?" + new Date().getTime(),
              "/assets/css/bootstrap.min.css?" + new Date().getTime(),
              "https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap",
            ],
            content_style: [
              ".article_blue_box { background-color: #27408b; padding: 15px; color:#fff; border-radius: 5px } \
            .article_blue_box p { padding:0; color:#fff; } \
            .article_grey_box { background-color: #ececec; padding: 15px; color:#666; border-radius: 5px } \
            .article_grey_box p { padding:0; color:#666; } \
            .article_orange_box { background-color: #f99f1b; padding: 15px; color:#fff; border-radius: 5px } \
            .article_orange_box p { padding:0; color:#fff; } \
            .article_teal_box { background-color: #1f8696; padding: 15px; color:#fff; border-radius: 5px } \
            .article_teal_box p { padding:0; color:#fff; } \
            .article_pink_box { background-color: #ffc4e9; padding: 15px; color:#fff; border-radius: 5px } \
            .article_pink_box p { padding:0; color:#fff; } \
            .article_white_box { background-color: #ffffff; padding: 15px; color:#666; border-radius: 5px } \
            .article_white_box p { padding:0; color:#666; } \
            .flex-container { display: flex; } \
            .flex-child { flex: 1; border: 1px dotted; padding: 1em } \
            .flex-child:first-child { margin-right: 5px; } \
            .flex-child:last-child { margin-right: 0; }",
            ],
            toolbar1:
              "undo redo | formatselect fontsizeselect | bold italic underline strikethrough forecolor backcolor | image media link anchor table | \
               alignleft aligncenter alignright alignjustify | \
               bullist numlist outdent indent blockquote",
            toolbar2:
              "pastetext paste charmap emoticons nonbreaking hr visualblocks searchreplace removeformat code fullscreen styleselect tooltip 1column 2columns 3columns",
            placeholder: "Započnimo sa stvaranjem sadržaja ...",
            setup: function (editor) {
              editor.ui.registry.addButton("tooltip", {
                text: "Oblačić s tekstom",
                onAction: function (_) {
                  editor.insertContent(
                    '&nbsp; <a href="#" data-toggle="tooltip" class="bootstrap_tooltip_link" title="Ovdje upišite sadržaj oblačića">oblačić</a> '
                  );
                },
              });
              editor.ui.registry.addButton("1column", {
                text: "1 kolona",
                onAction: function (_) {
                  editor.insertContent(
                    '<div class="flex-container">\
                    <div class="flex-child"><p>Kolona 1</p></div>\
                  </div><p/>'
                  );
                },
              });
              editor.ui.registry.addButton("2columns", {
                text: "2 kolone",
                onAction: function (_) {
                  editor.insertContent(
                    '<div class="flex-container">\
                    <div class="flex-child"><p>Kolona 1</p></div>\
                    <div class="flex-child"><p>Kolona 2</p></div>\
                  </div><p/>'
                  );
                },
              });
              editor.ui.registry.addButton("3columns", {
                text: "3 kolone",
                onAction: function (_) {
                  editor.insertContent(
                    '<div class="flex-container">\
                    <div class="flex-child"><p>Kolona 1</p></div>\
                    <div class="flex-child"><p>Kolona 2</p></div>\
                    <div class="flex-child"><p>Kolona 3</p></div>\
                  </div><p/>'
                  );
                },
              });
            },
            style_formats: [
              {
                title: "Containers",
                items: [
                  { title: "citat", block: "cite", wrapper: true },
                  {
                    name: "Plavi okvir",
                    title: "Plavi okvir",
                    block: "div",
                    wrapper: true,
                    classes: ["article_blue_box"],
                    merge_siblings: true,
                  },
                  {
                    name: "Sivi okvir",
                    title: "Sivi okvir",
                    block: "div",
                    wrapper: true,
                    classes: ["article_grey_box"],
                    merge_siblings: true,
                  },
                  {
                    name: "Narančasti okvir",
                    title: "Narančasti okvir",
                    block: "div",
                    wrapper: true,
                    classes: ["article_orange_box"],
                    merge_siblings: true,
                  },
                  {
                    name: "Tirkizni okvir",
                    title: "Tirkizni okvir",
                    block: "div",
                    wrapper: true,
                    classes: ["article_teal_box"],
                    merge_siblings: true,
                  },
                  {
                    name: "Rozi okvir",
                    title: "Rozi okvir",
                    block: "div",
                    wrapper: true,
                    classes: ["article_pink_box"],
                    merge_siblings: true,
                  },
                  {
                    name: "Bijeli okvir",
                    title: "Bijeli okvir",
                    block: "div",
                    wrapper: true,
                    classes: ["article_white_box"],
                    merge_siblings: true,
                  },
                ],
              },
            ],
            image_class_list: [
              { title: "None", value: "" },
              { title: "Responsive image", value: "img-fluid" },
            ],
            rel_list: [
              { title: "None", value: "" },
              { title: "Lightbox", value: "lightbox-image" },
              { title: "no-follow", value: "nofollow" },
            ],
            link_class_list: [
              { title: "None", value: "" },
              { title: "Oblačić s tekstom", value: "tooltip_link" },
            ],
          }}
          {...otherProps}
        />
        {hasError && error && <div className="invalid-feedback">{error}</div>}
      </div>
      <MediaManager
        open={showMediaManager}
        onClose={() => setShowMediaManager(false)}
        onSelect={handleMediaSelect}
      />
    </>
  );
};

export default memo(TinyMce);
