import * as yup from "yup";

export const getCategoryValidation = () => {
  const contentSchema = {
    name: yup.string().required("Naziv je obavezan"),
  };

  const categorySchema = {
    contents: yup.array().of(yup.object().shape(contentSchema)),
  };

  return yup.object().shape(categorySchema);
};
