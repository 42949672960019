import { apiCall } from "../api";

export const getTemplates = async (type) => {
  let url = "/api/v1/templates";
  if (type) {
    url += `?type=${type}`;
  }

  const res = await apiCall.get(url);

  return res.data;
};
