import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import Select from "react-select";
import { useToasts } from "react-toast-notifications";
import { Save, Trash, Check, X, ExternalLink } from "react-feather";
import Datetime from "react-datetime";
import moment from "moment";

import { transformToInitialArticle } from "src/services/articles/transformations";
import { getCategoryList } from "src/services/categories";
import { MAIN_LANGUAGE, USED_LANGUAGES } from "src/consts";
import {
  deleteArticle,
  getArticle,
  postArticle,
  putArticle,
  toggleActive,
} from "src/services/articles";
import { TextField } from "src/ui/components/TextField";
import { TinyMce } from "src/ui/components/TinyMce";
import { postBlock } from "src/services/blocks";

import { getArticleValidation } from "./validations";
import { transformArticleToBlock } from "./transformations";
import { confirmAlert } from "react-confirm-alert";
import { getTemplates } from "src/services/templates";
import { getForms } from "src/services/forms";
import { ImageForm } from "src/ui/components/ImageForm";

const ArticlesForm = (props) => {
  const { id: activeId } = useParams();
  const history = useHistory();
  const { addToast } = useToasts();

  const [article, setArticle] = useState(
    transformToInitialArticle(USED_LANGUAGES)
  );
  const [activeLang, setActiveLang] = useState(MAIN_LANGUAGE);
  const [errors, setErrors] = useState({});
  const [categories, setCategories] = useState([]);
  const [addToSlider, setAddToSlider] = useState(false);
  const [addToFeatured, setAddToFeatured] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [forms, setForms] = useState([]);

  const selectedCategories = useMemo(
    () => categories.filter((c) => article.category_ids.includes(c.id)),
    [categories, article.category_ids]
  );

  const selectedTemplate = useMemo(
    () => templates.find((t) => t.id === article.template_id) || null,
    [templates, article.template_id]
  );

  const selectedForm = useMemo(
    () => forms.find((f) => f.id === article.form_id) || null,
    [forms, article.form_id]
  );

  // fetching article
  useEffect(() => {
    if (!activeId) {
      setArticle(transformToInitialArticle(USED_LANGUAGES));
      return;
    }

    (async () => {
      try {
        const resArticle = await getArticle(activeId);
        setArticle(resArticle);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [activeId]);

  // fetch other data
  useEffect(() => {
    (async () => {
      try {
        const resCategories = await getCategoryList();
        setCategories(resCategories);
      } catch (e) {
        console.error(e);
      }

      try {
        const resTemplates = await getTemplates("article");
        setTemplates(resTemplates.data);
      } catch (e) {
        console.error(e);
      }

      try {
        const resForms = await getForms();
        setForms(resForms.data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const handleLangClicked = (lang) => (e) => {
    e.preventDefault();
    setActiveLang(lang);
  };

  const handleContentChange = (lang) => (e) => {
    const contentIndex = article.contents.findIndex(
      (c) => c.language_code === lang
    );

    if (contentIndex === -1) {
      return;
    }

    setArticle((old) => ({
      ...old,
      contents: [
        ...old.contents.slice(0, contentIndex),
        {
          ...old.contents[contentIndex],
          [e.target.name]: e.target.value,
        },
        ...old.contents.slice(contentIndex + 1),
      ],
    }));
  };

  const handleQuillContentChange = (lang) => (value) => {
    const contentIndex = article.contents.findIndex(
      (c) => c.language_code === lang
    );

    if (contentIndex === -1) {
      return;
    }

    setArticle((old) => ({
      ...old,
      contents: [
        ...old.contents.slice(0, contentIndex),
        {
          ...old.contents[contentIndex],
          content: value,
        },
        ...old.contents.slice(contentIndex + 1),
      ],
    }));
  };

  const handleQuillIntroChange = (lang) => (value) => {
    const contentIndex = article.contents.findIndex(
      (c) => c.language_code === lang
    );

    if (contentIndex === -1) {
      return;
    }

    setArticle((old) => ({
      ...old,
      contents: [
        ...old.contents.slice(0, contentIndex),
        {
          ...old.contents[contentIndex],
          intro: value,
        },
        ...old.contents.slice(contentIndex + 1),
      ],
    }));
  };

  const handleCategoriesChange = (values) =>
    setArticle((old) => ({
      ...old,
      category_ids: values ? values.map((v) => v.id) : [],
    }));

  const handleTextFieldChange = (e) => {
    setArticle((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  const handleSaveClicked = async () => {
    setErrors({});

    let successMsg = "";

    // validation
    try {
      await getArticleValidation().validate(article, { abortEarly: false });
    } catch (err) {
      setErrors(
        err.inner.reduce((acc, e) => ({ ...acc, [e.path]: e.message }), {})
      );
      return;
    }

    let newArticle = {};

    // post
    if (!activeId) {
      try {
        newArticle = await postArticle(article);
        setArticle(newArticle);
      } catch (e) {
        console.error(e);
        return;
      }

      successMsg = "Dodan je novi članak";
    } else {
      // put
      try {
        newArticle = await putArticle(activeId, article);
        setArticle(newArticle);
      } catch (e) {
        console.error(e);
        return;
      }

      successMsg = "Članak je uređen";
    }

    // block slider
    if (addToSlider) {
      await postBlock(transformArticleToBlock(newArticle, "slide"));
    }

    // block featured
    if (addToFeatured) {
      await postBlock(transformArticleToBlock(newArticle, "featured"));
    }

    if (!activeId) {
      history.push(`/articles/${newArticle.id}/edit`);
    }

    addToast(successMsg, { appearance: "success" });
  };

  const handleDateChange = (name) => (date) => {
    const formatedDate =
      date instanceof moment && date.isValid()
        ? date.format("YYYY-MM-DD HH:mm:ss")
        : null;

    setArticle((old) => ({
      ...old,
      [name]: formatedDate,
    }));
  };

  const handleMainImageChange = (img) => {
    setArticle((old) => ({ ...old, main_image: img }));
  };

  const handleDeleteClicked = () => {
    confirmAlert({
      title: "Brisanje članka",
      message: "Jeste li sigurni da želite obrisati članak?",
      buttons: [
        {
          label: "Da",
          onClick: async () => {
            await deleteArticle(activeId);
            history.push("/articles");
            addToast("Članak je obrisan", { appearance: "success" });
          },
        },
        {
          label: "Ne",
          onClick: () => {},
        },
      ],
    });
  };

  const handleTemplateChange = (t) => {
    setArticle((old) => ({ ...old, template_id: t ? t.id : null }));
  };

  const handleFormChange = (form) =>
    setArticle((old) => ({ ...old, form_id: form ? form.id : null }));

  const handlePreviewClicked = () => {
    const webUrl = process.env.REACT_APP_WEB_URL;
    window.open(webUrl + article.path, "_blank").focus();
  };

  const handleToggleActiveClicked = async () => {
    const resArticle = await toggleActive(article.id);
    setArticle(resArticle);
    addToast(
      resArticle.active ? "Članak je objevljane" : "Članak je neobjavljen",
      { appearance: "success" }
    );
  };

  return (
    <>
      <div className="col-lg-8 col-xl-9 mg-b-50">
        <div className="card">
          <div className="card-header">
            <h6 className="mg-b-0">
              {!!activeId ? "Uređivanje članka" : "Novi članak"}{" "}
              <span className="mg-r-20"></span>{" "}
              <button
                type="button"
                className="btn btn-xs btn-outline-secondary"
                onClick={handleSaveClicked}
              >
                <Save style={{ height: "12px", marginTop: "-4px" }} /> Spremi
              </button>
              {!!activeId && (
                <button
                  type="button"
                  className="btn btn-xs btn-outline-secondary ml-1"
                  onClick={handleDeleteClicked}
                >
                  <Trash style={{ height: "12px", marginTop: "-4px" }} /> Obriši
                </button>
              )}
              {!!activeId && (
                <button
                  type="button"
                  className="btn btn-xs btn-outline-secondary ml-1"
                  onClick={handlePreviewClicked}
                >
                  <ExternalLink style={{ height: "12px", marginTop: "-4px" }} />{" "}
                  Pregled
                </button>
              )}
              {!!activeId && article.active && (
                <button
                  type="button"
                  className="btn btn-xs btn-outline-success ml-1"
                  onClick={handleToggleActiveClicked}
                >
                  <Check style={{ height: "12px", marginTop: "-4px" }} />{" "}
                  Objavljen
                </button>
              )}
              {!!activeId && !article.active && (
                <button
                  type="button"
                  className="btn btn-xs btn-outline-danger ml-1"
                  onClick={handleToggleActiveClicked}
                >
                  <X style={{ height: "12px", marginTop: "-4px" }} /> Nije
                  objavljen
                </button>
              )}
            </h6>
          </div>

          <div className="card-body pd-lg-25">
            <div>
              <ul className="nav nav-line">
                {USED_LANGUAGES.map((lang) => (
                  <li className="nav-item" key={lang}>
                    <a
                      href="#"
                      className={`nav-link ${
                        activeLang === lang ? "active" : ""
                      }`}
                      onClick={handleLangClicked(lang)}
                    >
                      {lang.toUpperCase()}
                    </a>
                  </li>
                ))}
              </ul>

              <div className="tab-content mg-t-20">
                {USED_LANGUAGES.map((lang, index) => (
                  <div
                    className={`tab-pane fade ${
                      activeLang === lang ? "show active" : ""
                    }`}
                    key={lang}
                  >
                    <TextField
                      label="Naslov"
                      name="title"
                      onChange={handleContentChange(lang)}
                      value={_.get(article, `contents[${index}].title`)}
                      hasError={!!_.get(errors, `contents[${index}].title`)}
                      error={_.get(errors, `contents[${index}].title`)}
                    />
                    <TextField
                      label="Slug"
                      name="slug"
                      onChange={handleContentChange(lang)}
                      value={_.get(article, `contents[${index}].slug`)}
                    />
                    <TextField
                      label="Opis objave (za Facebook, Google...)"
                      name="seo_meta"
                      onChange={handleContentChange(lang)}
                      value={_.get(article, `contents[${index}].seo_meta`)}
                    />
                    <TinyMce
                      label="Intro"
                      onEditorChange={handleQuillIntroChange(lang)}
                      value={_.get(article, `contents[${index}].intro`)}
                    />
                    <TinyMce
                      label="Sadržaj"
                      onEditorChange={handleQuillContentChange(lang)}
                      value={_.get(article, `contents[${index}].content`)}
                    />
                  </div>
                ))}
              </div>
            </div>

            <hr />
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-4 col-xl-3 mg-t-10 mg-lg-t-0 mg-b-50">
        <div className="card">
          <div className="card-header">
            <h6 className="mg-b-0">Postavke</h6>
          </div>

          <div className="card-body pd-lg-25">
            <div className="row">
              <div className="form-group col-lg-12">
                <label>Kategorije</label>
                <Select
                  options={categories}
                  placeholder="Odaberite kategorije"
                  isMulti
                  isSearchable
                  formatOptionLabel={(c) => c.name}
                  getOptionValue={(c) => c.id}
                  getOptionLabel={(c) => c.name}
                  value={selectedCategories}
                  onChange={handleCategoriesChange}
                  name="name"
                />
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="form-group col-lg-12">
                <h6 className="mg-b-0">Ubaci u blok:</h6>
              </div>
              <div className="form-group col-lg-12 col-xl-12">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="slider"
                    checked={addToSlider}
                    onChange={(e) => setAddToSlider(e.target.checked)}
                  />
                  <label className="custom-control-label" htmlFor="slider">
                    Naslovnica - Slider
                  </label>
                </div>
              </div>

              <div className="form-group col-lg-12 col-xl-12">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="izdvojeno"
                    checked={addToFeatured}
                    onChange={(e) => setAddToFeatured(e.target.checked)}
                  />
                  <label className="custom-control-label" htmlFor="izdvojeno">
                    Naslovnica - Izdvojeno
                  </label>
                </div>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-lg-12">
                <ImageForm
                  label="Glavna slika"
                  imageUrl={article.main_image}
                  setImageUrl={handleMainImageChange}
                  helperText="Preporučena dimenzija: 1200px x 800px i više"
                />
              </div>
            </div>

            <div className="clearfix"></div>
            <hr />

            <div className="row">
              <div className="form-group col-lg-12 mg-t-10">
                <TextField
                  name="main_image_signature"
                  onChange={handleTextFieldChange}
                  value={article.main_image_signature}
                  label="Potpis glavne slike"
                  placeholder="npr. Pixabay, Depositphotos, Shutterstock..."
                />
              </div>
            </div>

            <h6 className="mg-b-5">Vrijeme objave</h6>
            <div className="mg-b-5">
              <Datetime
                dateFormat="DD.MM.YYYY."
                timeFormat="HH:mm"
                onChange={handleDateChange("active_from")}
                value={
                  article.active_from ? moment(article.active_from) : undefined
                }
                inputProps={{
                  placeholder: "Vrijeme objave",
                }}
              />
            </div>

            <h6 className="mg-b-10 mg-t-25">Trajanje objave</h6>
            <div className="row pd-l-15">
              <div className="custom-control custom-radio  mg-r-40 mg-b-10">
                <input
                  type="radio"
                  id="zauvijek"
                  name="customRadio"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="zauvijek">
                  Zauvijek
                </label>
              </div>

              <div className="custom-control custom-radio mg-b-10">
                <input
                  type="radio"
                  id="ograniceno"
                  name="customRadio"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="ograniceno">
                  Ograničeno
                </label>
              </div>
            </div>
            <div className="show_kill_switch_timer">
              <div className="mg-b-5">
                <Datetime
                  dateFormat="DD.MM.YYYY."
                  timeFormat="HH:mm"
                  onChange={handleDateChange("active_to")}
                  value={
                    article.active_to ? moment(article.active_to) : undefined
                  }
                  inputProps={{
                    placeholder: "Trajanje objave",
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-lg-12 mg-t-5">
                <TextField
                  name="author"
                  onChange={handleTextFieldChange}
                  value={article.author}
                  label="Autor"
                  placeholder="Ime autora"
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-lg-12">
                <h6 className="mg-b-5">Predložak</h6>
                <div className="mg-b-5">
                  <Select
                    options={templates}
                    placeholder="Odaberite predložak"
                    isSearchable
                    isClearable
                    value={selectedTemplate}
                    formatOptionLabel={(c) => c.name}
                    getOptionValue={(c) => c.id}
                    getOptionLabel={(c) => c.name}
                    onChange={handleTemplateChange}
                  />
                </div>
              </div>

              <div className="form-group col-lg-12">
                <h6 className="mg-b-5">Forma</h6>
                <div className="mg-b-5">
                  <Select
                    options={forms}
                    placeholder="Odaberite formu"
                    isSearchable
                    isClearable
                    value={selectedForm}
                    formatOptionLabel={(c) => c.name}
                    getOptionValue={(c) => c.id}
                    getOptionLabel={(c) => c.name}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-lg-12">
                <h6 className="mg-b-5">Zaključaj objavu lozinkom</h6>
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="zakljucano"
                    checked={article.locked}
                    onChange={(e) =>
                      setArticle((old) => ({
                        ...old,
                        locked: e.target.checked,
                      }))
                    }
                  />
                  <label className="custom-control-label" htmlFor="zakljucano">
                    NE / DA
                  </label>
                </div>
                {article.locked && (
                  <div className="mg-b-5 mg-t-5">
                    <TextField
                      name="password"
                      onChange={handleTextFieldChange}
                      value={article.password}
                      placeholder="Lozinka"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="form-group col-lg-12">
                <h6 className="mg-b-5">
                  Omogući čitanje samo za grupe korisnika
                </h6>
                <div className="mg-b-5">
                  <Select
                    options={categories}
                    placeholder="Odaberi grupe"
                    isMulti
                    isSearchable
                    formatOptionLabel={(c) => c.name}
                    getOptionValue={(c) => c.id}
                    getOptionLabel={(c) => c.name}
                    value={selectedCategories}
                    onChange={handleCategoriesChange}
                    name="name"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticlesForm;
