import { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";

import { getArticles } from "src/services/articles";
import { getCategoryList } from "src/services/categories";
import { getUsers } from "src/services/users";
import { ReactTable } from "src/ui/components/Table";

import { ArticleFilters } from "./components";
import { articleColumns } from "./consts";

const ArticlesPage = () => {
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({ title: "", category_id: null });

  useEffect(() => {
    (async () => {
      // await fetchArticles(1, 25);

      try {
        const resCategories = await getCategoryList();
        setCategories(resCategories);
      } catch (e) {
        console.error(e);
      }

      try {
        const { data: resUsers } = await getUsers();
        setUsers(resUsers);
      } catch (e) {
        console.error(e);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedApiCall = useDebouncedCallback(() => {
    fetchArticles(1, 25);
  }, 500);

  useEffect(() => {
    debouncedApiCall.callback();
  }, [debouncedApiCall, filters]);

  const fetchArticles = async (page, pageSize) => {
    setLoading(true);

    try {
      const { data: resArticles, meta } = await getArticles(
        page,
        pageSize,
        filters
      );
      setArticles(resArticles);
      setTotalPages(meta.last_page);
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  return (
    <ReactTable
      columns={articleColumns}
      data={articles}
      onPaginationChange={fetchArticles}
      isLoading={loading}
      totalPages={totalPages}
      FiltersRow={
        <ArticleFilters
          meta={{ categories, users }}
          filters={filters}
          setFilters={setFilters}
        />
      }
    />
  );
};

export default ArticlesPage;
