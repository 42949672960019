import { User } from "./types";

export const transformToUser = (res: any): User => {
  return {
    id: res.id,
    first_name: res.first_name,
    last_name: res.last_name,
    email: res.email,
    username: res.username,
  };
};

export const transformToUserRequest = (user: User): any => {
  return {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    username: user.username,
  };
};
