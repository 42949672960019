import { apiCall, transformToPagination } from "../api";
import { transformToUser } from "./transformations";
import { User } from "./types";

export const getUsers = async () => {
  const res = await apiCall.get("/api/v1/users");

  return transformToPagination(res.data, transformToUser);
};

export const getUser = async (id: number) => {
  const res = await apiCall.get(`/api/v1/users/${id}`);

  return transformToUser(res.data);
};

export const postUser = async (user: User) => {
  const res = await apiCall.post("/api/v1/users", user);

  return transformToUser(res.data);
};

export const putUser = async (user: User) => {
  const res = await apiCall.put(`/api/v1/users/${user.id}`, user);

  return transformToUser(res.data);
};
