import { memo, useState } from "react";
import { Image, Trash } from "react-feather";

import { API_URL } from "src/services/api";

import { MediaManager } from "../MediaManager";

const ImageForm = ({ label, imageUrl, setImageUrl, helperText }) => {
  const [showMediaManager, setShowMediaManager] = useState(false);

  const handleRemoveImage = () => {
    setImageUrl(null);
  };

  const handleSelectFile = (file) => {
    setImageUrl(file);

    setShowMediaManager(false);
  };

  return (
    <>
      <label>{label}</label>
      <div className="custom-file mg-b-10">
        <button
          type="button"
          className="btn btn-xs btn-outline-secondary"
          onClick={() => setShowMediaManager(true)}
        >
          <Image style={{ height: "12px", marginTop: "-2px" }} /> Odaberi
        </button>
        <button
          type="button"
          className="btn btn-xs btn-outline-secondary"
          onClick={handleRemoveImage}
        >
          <Trash style={{ height: "12px", marginTop: "-4px" }} /> Poništi
        </button>
        {imageUrl !== null && (
          <img
            src={API_URL + "/storage/" + imageUrl}
            style={{
              width: "100%",
              marginTop: "10px",
              marginBottom: "10px",
            }}
            alt=""
          />
        )}
        {helperText}
      </div>
      <MediaManager
        open={showMediaManager}
        onClose={() => setShowMediaManager(false)}
        onSelect={handleSelectFile}
      />
    </>
  );
};

export default memo(ImageForm);
