import { Home, Edit, Folder, User } from "react-feather";

const appName = process.env.REACT_APP_NAME;

export const leftNavigation = [
  {
    label: "Dashboard",
    items: [
      {
        label: "Dashboard",
        icon: <Home />,
        url: "/",
      },
    ],
  },
  {
    label: "Sadržaj",
    items: [
      {
        label: "Članci",
        icon: <Edit />,
        subItems: [
          { label: "Svi članci", url: "/articles" },
          { label: "Novi članak", url: "/articles/create" },
        ],
      },
      {
        label: "Kategorije",
        icon: <Folder />,
        subItems: [
          { label: "Pregled kategorija", url: "/categories" },
          { label: "Nova kategorija", url: "/categories/create" },
        ],
      },
      {
        label: "Blokovi",
        icon: <Folder />,
        subItems: [
          { label: "Navigacija", url: "/blocks/navigation" },
          { label: "Naslovnica - Slider", url: "/blocks/slides" },
          { label: "Naslovnica - Izdvojeno", url: "/blocks/pinned-news" },
          {
            label: "Naslovnica - Pravni savjeti",
            url: "/blocks/pinned-items",
            hidden: appName === "hkis",
          },
          {
            label: "Naslovnica - Citati",
            url: "/blocks/pinned-quotes",
            hidden: appName === "hkis",
          },
          {
            label: "Naslovnica - O nama linkovi",
            url: "/blocks/about-us-link",
            hidden: appName === "hkis",
          },
          {
            label: "Header - Plava tipka",
            url: "/blocks/top-button",
            hidden: appName === "hkis",
          },
          {
            label: "Footer - Linkovi",
            url: "/blocks/footer-link",
            hidden: appName === "hkis",
          },
          {
            label: "Blog Sidebar",
            url: "/blocks/blog-sidebar",
            hidden: appName === "hkis",
          },
        ],
      },
      {
        label: "Obrasci",
        icon: <Folder />,
        subItems: [
          { label: "Pregled obrazaca", url: "/forms" },
          { label: "Novi obrazac", url: "/forms/create" },
        ],
      },
      /* {
        label: "Media manager",
        icon: <FileText />,
        url: "",
      }, */
      /* {
        label: "Kalendar objava",
        icon: <Calendar />,
        url: "",
      }, */
    ],
  },
  {
    label: "Postavke sustava",
    items: [
      {
        label: "Korisnici",
        icon: <User />,
        subItems: [
          { label: "Pregled korisnika", url: "/users" },
          { label: "Novi korisnik", url: "/users/create" },
          { label: "Pregled grupa", url: "/user-groups" },
          { label: "Nova grupa", url: "/user-groups/create" },
        ],
      },
    ],
  },
];
