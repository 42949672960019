import { useMemo, useState, useEffect } from "react";
import SortableTree from "react-sortable-tree";
import { Plus, Trash } from "react-feather";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";
import { useToasts } from "react-toast-notifications";

import {
  deleteNavigationPart,
  getNavigationParts,
  getNavigations,
  getNavigationTree,
  postNavigationPart,
  updateNavigationTree,
} from "src/services/navigations";
import { defaultNavigationPart } from "src/services/navigations/consts";
import { getArticleList } from "src/services/articles";
import { getCategoryList } from "src/services/categories";

const Navigation = () => {
  const { addToast } = useToasts();

  const [selectedNav, setSelectedNav] = useState(null);
  const [navigations, setNavigations] = useState([]);
  const [navigationPartsTree, setNavigationPartsTree] = useState([]);
  const [navigationParts, setNavigationParts] = useState([]);
  const [navPart, setNavPart] = useState(defaultNavigationPart);
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const selectedParentNav = useMemo(
    () => navigationParts.find((p) => p.id === navPart.parent_id) || null,
    [navigationParts, navPart]
  );

  useEffect(() => {
    (async () => {
      const resNavigations = await getNavigations();
      setNavigations(resNavigations);

      if (resNavigations.length) {
        const nav = resNavigations[0];
        setSelectedNav(nav);
        const resNavPartsTree = await getNavigationTree(nav.id);
        setNavigationPartsTree(resNavPartsTree);

        const resNavParts = await getNavigationParts(nav.id);
        setNavigationParts(resNavParts);

        const rootPart = resNavParts.find((p) => p.parent_id == null);
        setNavPart((old) => ({ ...old, parent_id: rootPart.id }));
      }

      const resArticles = await getArticleList();
      setArticles(resArticles);

      const resCategories = await getCategoryList();
      setCategories(resCategories);
    })();
  }, []);

  const handleParentChange = (p) => {
    setNavPart((old) => ({ ...old, parent_id: p ? p.id : null }));
  };

  const handleTextFieldChange = (e) => {
    setNavPart((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  const addNewNavPart = async () => {
    try {
      const newPart = await postNavigationPart(navPart);
      setNavigationParts((old) => [...old, newPart]);
      // setNavPart(defaultNavigationPart);

      const resNavPartsTree = await getNavigationTree(selectedNav.id);
      setNavigationPartsTree(resNavPartsTree);
    } catch {
      return;
    }
  };

  const selectedArticleClicked = () => {
    if (selectedArticle) {
      setNavPart((old) => ({
        ...old,
        title: selectedArticle.title,
        path: selectedArticle.path,
      }));
    }
  };

  const selectedCategoryClicked = () => {
    if (selectedCategory) {
      setNavPart((old) => ({
        ...old,
        title: selectedCategory.name,
        path: selectedCategory.path,
      }));
    }
  };

  const handleTreeChange = (tree) => {
    updateNavigationTree(tree);
    setNavigationPartsTree(tree);
  };

  const handleDeleteClicked = (id) => () => {
    confirmAlert({
      title: "Brisanje navigacije",
      message:
        "Jeste li sigurni da želite obrisati stavku navigacije? Sve stavke koje se nalaze ispod nje će također biti obrisane.",
      buttons: [
        {
          label: "Da",
          onClick: async () => {
            await deleteNavigationPart(id);
            const resNavPartsTree = await getNavigationTree();
            setNavigationPartsTree(resNavPartsTree);
            addToast("Stavka je obrisana", { appearance: "success" });
          },
        },
        {
          label: "Ne",
          onClick: () => {},
        },
      ],
    });
  };

  const handleNavChange = async (nav) => {
    setSelectedNav(nav);

    if (nav) {
      const resNavPartsTree = await getNavigationTree(nav.id);
      setNavigationPartsTree(resNavPartsTree);

      const resNavParts = await getNavigationParts(nav.id);
      setNavigationParts(resNavParts);

      const rootPart = resNavParts.find((p) => p.parent_id == null);
      setNavPart((old) => ({ ...old, parent_id: rootPart.id }));
    } else {
      setNavigationPartsTree([]);
      setNavigationParts([]);
    }
  };

  return (
    <>
      <div className="col-lg-3 col-xl-3">
        <div className="card">
          <div className="card-header">
            <h6 className="mg-b-0">Dodaj stavke menija</h6>
          </div>
          <div className="card-body pd-lg-25">
            <div className="mb-3">
              <h6>Lokacija stavke menija</h6>
              <Select
                options={navigationParts}
                placeholder="Odaberite"
                isSearchable
                formatOptionLabel={(p) => p.title}
                getOptionValue={(p) => p.id}
                getOptionLabel={(p) => p.title}
                value={selectedParentNav}
                onChange={handleParentChange}
              />
            </div>
            <hr />
            <div className="accordion">
              <h6>Članci</h6>
              <div className="mg-b-5">
                <Select
                  options={articles}
                  placeholder="Odaberite"
                  isSearchable
                  isClearable
                  formatOptionLabel={(a) => a.title}
                  getOptionValue={(a) => a.id}
                  getOptionLabel={(a) => a.title}
                  value={selectedArticle}
                  onChange={setSelectedArticle}
                  className="mg-b-5"
                />
                <button
                  type="button"
                  className="btn btn-xs btn-outline-secondary"
                  onClick={selectedArticleClicked}
                >
                  <Plus style={{ height: "12px", marginTop: "-4px" }} /> Dodaj
                </button>
              </div>
              <hr />
              <h6>Kategorije</h6>
              <div className="mg-b-5">
                <Select
                  options={categories}
                  placeholder="Odaberite"
                  isSearchable
                  isClearable
                  formatOptionLabel={(c) => c.name}
                  getOptionValue={(c) => c.id}
                  getOptionLabel={(c) => c.name}
                  value={selectedCategory}
                  onChange={setSelectedCategory}
                  className="mg-b-5"
                />
                <button
                  type="button"
                  className="btn btn-xs btn-outline-secondary"
                  onClick={selectedCategoryClicked}
                >
                  <Plus style={{ height: "12px", marginTop: "-4px" }} /> Dodaj
                </button>
              </div>
              <hr />
              <h6>Custom link</h6>
              <div>
                <div className="mg-b-5">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Naziv"
                    name="title"
                    value={navPart.title}
                    onChange={handleTextFieldChange}
                  />
                </div>
                <div className="mg-b-5">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Link"
                    name="path"
                    value={navPart.path}
                    onChange={handleTextFieldChange}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-xs btn-outline-secondary"
                  onClick={addNewNavPart}
                >
                  <Plus style={{ height: "12px", marginTop: "-4px" }} /> Dodaj
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-9 col-xl-9">
        <div className="card">
          <div className="card-body pd-lg-25">
            <Select
              options={navigations}
              placeholder="Odaberite"
              isSearchable
              formatOptionLabel={(p) => p.name}
              getOptionValue={(p) => p.id}
              getOptionLabel={(p) => p.name}
              value={selectedNav}
              onChange={handleNavChange}
            />
            <SortableTree
              treeData={navigationPartsTree}
              onChange={handleTreeChange}
              isVirtualized={false}
              generateNodeProps={({ node }) => {
                return {
                  buttons: [
                    <button
                      className="btn btn-danger btn-xs"
                      onClick={handleDeleteClicked(node.id)}
                    >
                      <Trash />
                    </button>,
                  ],
                };
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;
