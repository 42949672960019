import moment from "moment";

import { initialArticle } from "./consts";

export const transformToInitialArticle = (langCodes) => {
  return {
    ...initialArticle,
    active_from: moment().format("YYYY-MM-DD HH:mm:ss"),
    contents: [
      ...langCodes.map((code) => ({
        language_code: code,
        title: "",
        slug: "",
        content: "",
        intro: "",
        seo_meta: "",
      })),
    ],
  };
};
