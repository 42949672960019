/* eslint-disable react-hooks/exhaustive-deps */
import { DataTable } from "src/ui/components/Table";

const GroupsPage = () => {

  return (
    <DataTable

    />
  );
};

export default GroupsPage;
