export const transformArticleToBlock = (article, blockType) => {
  return {
    image: article.main_image,
    type: blockType,
    contents: article.contents.map((c) => ({
      title: c.title,
      content: c.intro,
      language_code: c.language_code,
      url: article.path,
    })),
  };
};
