import { memo } from "react";
import { Link } from "react-router-dom";
import { Power, Edit } from "react-feather";

const UserRow = ({ row: user }) => {
  const handleActiveClicked = () => {
    alert("Not yet implemented");
  };

  return (
    <tr>
      <td>
        <b>{user.id}</b>
      </td>
      <td>{user.first_name}</td>
      <td>{user.last_name}</td>
      <td>{user.username}</td>
      <td>{user.email}</td>
      <td>
        <button
          type="button"
          className="btn btn-success btn-xs"
          onClick={handleActiveClicked}
        >
          <Power /> Aktivan
        </button>
      </td>
      <td>
        <Link to={`/users/${user.id}/edit`} className="btn btn-primary btn-xs">
          <Edit /> Uredi
        </Link>{" "}
      </td>
    </tr>
  );
};

export default memo(UserRow);
