export const initialCategory = {
  name: "",
  parent_id: null,
  template_id: null,
  content_view: null,
  contents: [],
  order: "desc",
  sidebar: [],
  is_locked: false,
  password: "",
};
