import moment from "moment";
import { initialFormField } from "./consts";

import { Form, FormField } from "./types";

export const transformToForm = (res: any): Form => ({
  id: res.id,
  created_at: res.created_at ? moment(res.created_at).toDate() : null,
  updated_at: res.updated_at ? moment(res.updated_at).toDate() : null,
  name: res.name,
  fields: JSON.parse(res.fields || "[]").map((arr: unknown[]) =>
    arr.map(transformToFormField)
  ),
  recipients: res.recipients,
});

export const transformToFormField = (res: any): FormField => ({
  ...initialFormField,
  ...res,
});

export const transformToRequestForm = (form: Form): any => {
  return {
    recipients: form.recipients,
    fields: JSON.stringify(form.fields),
    name: form.name,
  };
};
