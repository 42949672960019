import * as yup from "yup";

export const getArticleValidation = () => {
  const contentSchema = {
    title: yup.string().required("Naslov je obavezan"),
  };

  const articleSchema = {
    contents: yup.array().of(yup.object().shape(contentSchema)),
  };

  return yup.object().shape(articleSchema);
};
