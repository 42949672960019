import { Pagination, Pagination2 } from "./types";

export const transformToPagination = <T>(
  res: any,
  dataTransformation: (data: any) => T
): Pagination<T> => {
  return {
    data: res.data.map(dataTransformation),
    meta: {
      last_page: res.meta.last_page,
      per_page: res.meta.per_page,
      current_page: res.meta.current_page,
      total: res.meta.total,
    },
  };
};

export const transformToPagination2 = <T>(
  res: any,
  dataTransformation: (data: any) => T
): Pagination2<T> => {
  return {
    data: res.data.map(dataTransformation),
    meta: res.meta,
  };
};
