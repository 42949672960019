import { FormField } from "src/services/forms";

export const moveUp = (
  formFields: FormField[][],
  rowIndex: number,
  fieldIndex: number
) => {
  const rowFields = formFields[rowIndex];

  const field = rowFields[fieldIndex];
  const otherRowFields = [
    ...rowFields.slice(0, fieldIndex),
    ...rowFields.slice(fieldIndex + 1),
  ];

  // if is part of row
  if (rowFields.length > 1) {
    const newFormFields = [
      ...formFields.slice(0, rowIndex),
      [field],
      otherRowFields,
      ...formFields.slice(rowIndex + 1),
    ];

    return newFormFields;
  }

  const newFormFields = [
    ...formFields.slice(0, rowIndex - 1),
    [...formFields[rowIndex - 1], field],
    ...formFields.slice(rowIndex + 1),
  ];

  return newFormFields;
};

export const modeDown = (
  formFields: FormField[][],
  rowIndex: number,
  fieldIndex: number
) => {
  const rowFields = formFields[rowIndex];
  const field = rowFields[fieldIndex];
  const otherRowFields = [
    ...rowFields.slice(0, fieldIndex),
    ...rowFields.slice(fieldIndex + 1),
  ];

  if (rowFields.length > 1) {
    const newFormFields = [
      ...formFields.slice(0, rowIndex),
      otherRowFields,
      [field],
      ...formFields.slice(rowIndex + 1),
    ];

    return newFormFields;
  }

  const newFormFields = [
    ...formFields.slice(0, rowIndex),
    [...formFields[rowIndex + 1], field],
    ...formFields.slice(rowIndex + 2),
  ];

  return newFormFields;
};

export const moveLeft = (
  formFields: FormField[][],
  rowIndex: number,
  fieldIndex: number
) => {
  const rowFields = formFields[rowIndex];

  const newRowFields = [
    ...rowFields.slice(0, fieldIndex - 1),
    rowFields[fieldIndex],
    rowFields[fieldIndex - 1],
    ...rowFields.slice(fieldIndex + 1),
  ];

  return [
    ...formFields.slice(0, rowIndex),
    newRowFields,
    ...formFields.slice(rowIndex + 1),
  ];
};

export const moveRight = (
  formFields: FormField[][],
  rowIndex: number,
  fieldIndex: number
) => {
  const rowFields = formFields[rowIndex];

  const newRowFields = [
    ...rowFields.slice(0, fieldIndex),
    rowFields[fieldIndex + 1],
    rowFields[fieldIndex],
    ...rowFields.slice(fieldIndex + 2),
  ];

  return [
    ...formFields.slice(0, rowIndex),
    newRowFields,
    ...formFields.slice(rowIndex + 1),
  ];
};

export const deleteField = (
  formFields: FormField[][],
  rowIndex: number,
  fieldIndex: number
) => {
  if (formFields[rowIndex].length === 1) {
    const newFormFields = [
      ...formFields.slice(0, rowIndex),
      ...formFields.slice(rowIndex + 1),
    ];

    return newFormFields;
  }

  const newFormFields = [
    ...formFields.slice(0, rowIndex),
    [
      ...formFields[rowIndex].slice(0, fieldIndex),
      ...formFields[rowIndex].slice(fieldIndex + 1),
    ],
    ...formFields.slice(rowIndex + 1),
  ];

  return newFormFields;
};
