import { transformToCategory } from "./transformations";
import { apiCall } from "../api";

export const getCategories = async (page, pageSize) => {
  const res = await apiCall.get(
    `/api/v1/categories?page=${page}&pageSize=${pageSize}`
  );

  return res.data;
};

export const getCategory = async (id) => {
  const res = await apiCall.get(`/api/v1/categories/${id}`);

  return transformToCategory(res.data);
};

export const postCategory = async (category) => {
  const res = await apiCall.post("/api/v1/categories", category);

  return res.data;
};

export const putCategory = async (id, category) => {
  const res = await apiCall.put(`/api/v1/categories/${id}`, category);

  return res.data;
};

export const deleteCategory = async (id) => {
  await apiCall.delete(`/api/v1/categories/${id}`);
};

export const getCategoryList = async () => {
  const res = await apiCall.get(`/api/v1/categories/list`);

  return res.data.data;
};

export const getCategoriesTree = async () => {
  const res = await apiCall.get("/api/v1/categories/tree");

  return res.data;
};

export const updateCategoriesTree = async (tree) => {
  const res = await apiCall.post("/api/v1/categories/update-tree", { tree });

  return res.data;
};
