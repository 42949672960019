import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Plus } from "react-feather";

import { TextField } from "src/ui/components/TextField";

import { FormFieldAddProps } from "./types";

const FormFieldAdd: React.FC<FormFieldAddProps> = ({
  formFieldOption,
  onAdd,
}) => {
  const [title, setTitle] = useState("");
  const [error, setError] = useState(false);

  const handleAddClicked = () => {
    if (!title) {
      setError(true);
      return;
    }

    onAdd(formFieldOption.type, title);
    setTitle("");
    setError(false);
  };

  return (
    <Row className="mg-b-10">
      <Col xs={9}>
        <div className="pd-10" style={{ border: "1px dotted #333" }}>
          <TextField
            label={formFieldOption.title}
            placeholder="Upiši naziv"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            hasError={error}
          />
          {formFieldOption.hasOptions && (
            <Button variant="outline-secondary" size="sm">
              <Plus />
            </Button>
          )}
        </div>
      </Col>
      <Col xs={3}>
        <Button variant="outline-secondary" onClick={handleAddClicked}>
          <Plus /> Dodaj
        </Button>
      </Col>
    </Row>
  );
};

export default FormFieldAdd;
