import { memo } from "react";
import Select from "react-select";

const ArticleFilters = ({ meta, filters, setFilters }) => {
  const selectedCategory =
    meta.categories.find((c) => c.id === filters.category_id) || null;

  const handleCategoryChange = (cat) =>
    setFilters((old) => ({ ...old, category_id: cat ? cat.id : null }));

  const handleUserChange = () => {};

  const handleInputChange = (e) =>
    setFilters((old) => ({ ...old, [e.target.name]: e.target.value }));

  return (
    <tr>
      <td style={{ width: "54px" }}>
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="selectAllFilter"
          />
          <label
            className="custom-control-label"
            htmlFor="selectAllFilter"
          ></label>
        </div>
      </td>
      <td style={{ width: "70px" }}>
        <input
          type="text"
          className="form-control form-control-sm"
          placeholder="Po ID"
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control form-control-sm"
          placeholder="Pretraga po naslovu"
          name="title"
          value={filters.title}
          onChange={handleInputChange}
        />
      </td>
      <td style={{ width: "250px" }}>
        <Select
          options={meta.categories}
          placeholder="Odaberite kategorije"
          isSearchable
          isClearable
          formatOptionLabel={(c) => c.name}
          getOptionValue={(c) => c.id}
          getOptionLabel={(c) => c.name}
          value={selectedCategory}
          onChange={handleCategoryChange}
        />
      </td>
      <td style={{ width: "200px" }}>
        <div className="input-group">
          <input
            type="text"
            aria-label="Od"
            className="form-control form-control-sm datepicker"
            placeholder="Od"
          />
          <input
            type="text"
            aria-label="Do"
            className="form-control form-control-sm datepicker"
            placeholder="Do"
          />
        </div>
      </td>
      <td style={{ width: "80px" }} />
      <td style={{ width: "150px" }}>
        <Select
          options={meta.users}
          placeholder="Odaberite korisnika"
          isSearchable
          isClearable
          formatOptionLabel={(c) => c.username}
          getOptionValue={(c) => c.id}
          getOptionLabel={(c) => c.username}
          // value={filters["category_id"]}
          onChange={handleUserChange}
        />
      </td>
      <td style={{ width: "100px" }}>
        <select className="form-control">
          <option value="">Svi</option>
          <option value="1">Da</option>
          <option value="0">Ne</option>
        </select>
      </td>
    </tr>
  );
};

export default memo(ArticleFilters);
