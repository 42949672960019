/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { getUsers } from "src/services/users";
import { DataTable } from "src/ui/components/Table";

import { UserRow, UserFilters } from "./components";
import { userColumns } from "./consts";

const UsersPage = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const data = await getUsers();
      setUsers(data.data);
    } catch {
      // do nothing
    }
  };

  return (
    <DataTable
      columns={userColumns}
      data={users}
      RowComponent={UserRow}
      FiltersComponent={UserFilters}
      page={1}
      setPage={() => {}}
      loading={false}
      meta={{}}
    />
  );
};

export default UsersPage;
