import React from "react";
import { Dropdown } from "react-bootstrap";
import { Download, MoreVertical, Trash } from "react-feather";

import { deleteFile, downloadFile } from "src/services/storage";

import { getExtension, getFileName, getThumb } from "./helpers";
import { MediaManagerFileProps } from "./types";

const CustomToggle = React.forwardRef(
  ({ onClick }: { onClick: any }, ref: any) => (
    <a
      href=""
      ref={ref}
      className="dropdown-link"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <MoreVertical />
    </a>
  )
);

const MediaManagerFile: React.FC<MediaManagerFileProps> = ({
  file,
  onDelete,
  onSelect,
}) => {
  const handleDownloadClick = async () => {
    await downloadFile(file);
  };

  const handleDeleteClick = async () => {
    // if (!confirm("Da li ste sigurni da želite obrisati datoteku?")) {
    //   return;
    // }

    await deleteFile(file);
    onDelete();
  };

  const handleSelectClick = (e: any) => {
    e.preventDefault();

    onSelect();
  };

  return (
    <div className="col-sm-6 col-lg-3 col-xl-3 mg-b-10">
      <div className="card card-file">
        <div className="dropdown-file">
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} />
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleDownloadClick}>
                <Download />
                Preuzmi
              </Dropdown.Item>
              <Dropdown.Item onClick={handleDeleteClick}>
                <Trash /> Obriši
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div>
          <a href="#insert" onClick={handleSelectClick}>
            <img alt="" src={getThumb(file)} className="filemgr-image-thumb" />
          </a>
        </div>
        <div className="card-body">
          <h6>
            <a
              href="#insert"
              className="link-02"
              style={{ overflow: "hidden" }}
              onClick={handleSelectClick}
            >
              {getFileName(file)}
            </a>
          </h6>
          <p>{getExtension(file)}</p>
          {/* <span>1000.45kb</span> */}
        </div>
      </div>
    </div>
  );
};

export default MediaManagerFile;
