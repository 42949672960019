import { useState, useEffect, useRef } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { Save } from "react-feather";
import { NewDirectoryProps } from "./types";

const NewDirectory: React.FC<NewDirectoryProps> = ({ onSave, directories }) => {
  const inputRef = useRef<any>(null);
  const [name, setName] = useState("");
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSaveClick = () => {
    if (!name) {
      setError("Naziv je obavezan");
      return;
    }

    const index = directories
      .map((d) => d.toLowerCase())
      .findIndex((d) => d.split("/").pop() === name.toLowerCase());

    if (index > -1) {
      setError("Direktorij postoji");
      return;
    }

    onSave(name);
  };

  return (
    <InputGroup>
      <FormControl
        placeholder="New directory"
        value={name}
        onChange={(e: any) => setName(e.target.value)}
        ref={inputRef}
        style={{ borderColor: error ? "red" : "initial" }}
      />
      <InputGroup.Append>
        <Button onClick={handleSaveClick}>
          <Save color="white" />
        </Button>
      </InputGroup.Append>
      {error && (
        <FormControl.Feedback style={{ display: "block" }} type="invalid">
          {error}
        </FormControl.Feedback>
      )}
    </InputGroup>
  );
};

export default NewDirectory;
