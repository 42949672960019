import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Save, Trash } from "react-feather";
import { useToasts } from "react-toast-notifications";
import _ from "lodash";

import { transformToInitialBlock } from "src/services/blocks/transformations";
import { getBlock, postBlock, putBlock } from "src/services/blocks";
import { TextField } from "src/ui/components/TextField";
import { USED_LANGUAGES } from "src/consts";
import { getBlockItemsValidation } from "./validations";
import { ImageForm } from "src/ui/components/ImageForm";

const BlockPinnedItemsForm = () => {
  const { id: activeId } = useParams();
  const history = useHistory();
  const { addToast } = useToasts();

  const [block, setBlock] = useState({
    ...transformToInitialBlock(USED_LANGUAGES),
    type: "items",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (activeId) {
      (async () => {
        const resBlock = await getBlock(activeId);
        setBlock(resBlock);
      })();
    }
  }, [activeId]);

  const handleContentChange = (lang) => (e) => {
    const contentIndex = block.contents.findIndex(
      (c) => c.language_code === lang
    );

    if (contentIndex === -1) {
      return;
    }

    setBlock((old) => ({
      ...old,
      contents: [
        ...old.contents.slice(0, contentIndex),
        {
          ...old.contents[contentIndex],
          [e.target.name]: e.target.value,
        },
        ...old.contents.slice(contentIndex + 1),
      ],
    }));
  };

  const handleSaveClicked = async () => {
    // validation
    try {
      await getBlockItemsValidation().validate(block, { abortEarly: false });
    } catch (err) {
      setErrors(
        err.inner.reduce((acc, e) => ({ ...acc, [e.path]: e.message }), {})
      );
      return;
    }

    let successMsg = "";

    // post
    if (!activeId) {
      try {
        await postBlock(block);
      } catch (e) {
        console.error(e);
        return;
      }

      successMsg = "Dodan je novi blok";
    } else {
      // put
      try {
        await putBlock(activeId, block);
      } catch (e) {
        console.error(e);
        return;
      }

      successMsg = "Blok je uređen";
    }

    history.push("/blocks/pinned-items");

    addToast(successMsg, { appearance: "success" });
  };

  const handleImageChange = (image) => {
    setBlock((old) => ({ ...old, image }));
  };

  return (
    <div className="col-lg-12 col-xl-12">
      <div className="card">
        <div className="card-header">
          <h6 className="mg-b-0">
            Uredi objavu <span className="mg-r-20"></span>
            <button
              type="button"
              className="btn btn-xs btn-outline-secondary mg-r-5"
              onClick={handleSaveClicked}
            >
              <Save style={{ height: "12px", marginTop: "-4px" }} /> Spremi
            </button>
            <button
              type="button"
              className="btn btn-xs btn-outline-secondary mg-r-5"
            >
              <Trash style={{ height: "12px", marginTop: "-4px" }} /> Obriši
            </button>
            {/* <button type="button" className="btn btn-xs btn-success mg-r-5">
              <Power style={{ height: "12px", marginTop: "-4px" }} /> Prikazan
            </button>
            <button type="button" className="btn btn-xs btn-danger mg-r-5">
              <Power style={{ height: "12px", marginTop: "-4px" }} /> Skriven
            </button> */}
          </h6>
        </div>

        <div className="card-body pd-lg-25">
          <TextField
            label="Naslov"
            name="title"
            onChange={handleContentChange("hr")}
            value={_.get(block, `contents[0].title`)}
            hasError={!!_.get(errors, `contents[0].title`)}
            error={_.get(errors, `contents[0].title`)}
          />
          <TextField
            label="Link"
            name="url"
            onChange={handleContentChange("hr")}
            value={_.get(block, `contents[0].url`)}
            hasError={!!_.get(errors, `contents[0].url`)}
            error={_.get(errors, `contents[0].url`)}
          />
          <div className="row">
            <div className="col-md-3">
              <ImageForm
                label="Slika"
                imageUrl={block.image}
                setImageUrl={handleImageChange}
                helperText="Preporučena dimenzija: 1920px x 1130px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockPinnedItemsForm;
