import { apiCall } from "../api";
import { transformToNavigationPart } from "./transformations";

export const getNavigations = async () => {
  const res = await apiCall.get("/api/v1/navigations/list");

  return res.data;
};

export const getNavigationParts = async (id = 1) => {
  const res = await apiCall.get("/api/v1/navigations?navigationId=" + id);

  return res.data.map(transformToNavigationPart);
};

export const postNavigationPart = async (navPart) => {
  const res = await apiCall.post("/api/v1/navigations", navPart);

  return transformToNavigationPart(res.data);
};

export const getNavigationTree = async (id = 1) => {
  const res = await apiCall.get("/api/v1/navigations/tree/" + id);

  return res.data;
};

export const updateNavigationTree = async (tree, navId) => {
  const res = await apiCall.post("/api/v1/navigations/update-tree/" + navId, {
    tree,
  });

  return res.data;
};

export const deleteNavigationPart = async (id) => {
  await apiCall.delete(`/api/v1/navigations/${id}`);
};
