import { memo } from "react";
import { ArrowLeft, ArrowRight } from "react-feather";
import { useTable, usePagination } from "react-table";

import { ReactTableProps } from "./types";

const pageSizes = [25, 50, 100];

const ReactTable: React.FC<ReactTableProps<any>> = ({
  data,
  columns,
  onPaginationChange,
  isLoading,
  totalPages,
  FiltersRow = null,
}) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 25 },
      pageCount: totalPages,
      manualPagination: true,
    } as any,
    usePagination
  ) as any;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    // pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const handleNextPage = async () => {
    if (canNextPage) {
      nextPage();
      if (onPaginationChange) {
        await onPaginationChange(pageIndex + 2, pageSize);
      }
    }
  };

  const handlePreviousPage = async () => {
    if (canPreviousPage) {
      previousPage();
      if (onPaginationChange) {
        await onPaginationChange(pageIndex, pageSize);
      }
    }
  };

  const handlePageSizeChange = (size: number) => async () => {
    gotoPage(0);
    setPageSize(size);
    if (onPaginationChange) {
      await onPaginationChange(1, size);
    }
  };

  return (
    <div className="col-lg-12 col-xl-12">
      <div className="card">
        <div className="card-body pd-lg-25">
          <div className="d-flex justify-content-between mb-3">
            <div>
              <span className="mr-1">Broj rezultata po stranici: </span>
              {pageSizes.map((size, index) => (
                <button
                  key={`size_${index}`}
                  type="button"
                  className={`btn btn-xs mr-1 ${
                    pageSize === size ? "btn-secondary" : "btn-outline-light"
                  }`}
                  onClick={handlePageSizeChange(size)}
                >
                  {size}
                </button>
              ))}
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered" {...getTableProps()}>
              <thead>
                {FiltersRow}
                {headerGroups.map((headerGroup: any) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {isLoading ? (
                  <tr>
                    <td colSpan={columns.length} align="center">
                      <div className="text-center">
                        <div className="spinner-border"></div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  page.map((row: any) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell: any) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <div>
                Stranica {pageIndex + 1} od {pageOptions.length}
              </div>
              <div>
                <div className="btn-group" role="group" aria-label=".">
                  {canPreviousPage && (
                    <button
                      type="button"
                      className="btn btn-outline-light"
                      onClick={handlePreviousPage}
                      disabled={!canPreviousPage}
                    >
                      <ArrowLeft />
                    </button>
                  )}
                  {canNextPage && (
                    <button
                      type="button"
                      className="btn btn-outline-light"
                      onClick={handleNextPage}
                    >
                      <ArrowRight />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ReactTable);
