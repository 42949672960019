import React from "react";

import { MediaManagerFile } from "./components";
import { MediaManagerContentProps } from "./types";

const MediaManagerContent: React.FC<MediaManagerContentProps> = ({
  files,
  setFiles,
  onFileSelect,
}) => {
  const handleDeleteFile = (file: string) => {
    const index = files.indexOf(file);

    if (index === -1) {
      return;
    }

    setFiles([...files.slice(0, index), ...files.slice(index + 1)]);
  };

  return (
    <div className="filemgr-content">
      <div className="filemgr-content-header">
        <h4 className="mg-b-0">Sadržaj mape</h4>
      </div>
      <div className="filemgr-content-body">
        <div className="pd-20 pd-lg-25 pd-xl-30">
          {/* <label className="d-block tx-medium tx-10 tx-uppercase tx-sans tx-spacing-1 tx-color-03 mg-b-15">
            Status uploada
          </label>
          <div className="row row-xs">
            <div className="col-sm-12 col-lg-12 col-xl-12 mg-b-10">
              <div className="media media-folder">
                <i className="fas fa-sync fa-spin pd-5"></i>
                <div className="media-body">
                  <h6>
                    <a href="" className="link-02">
                      depositphotos-5g-mobilne-mreze-telekomunikacije-4.jpg
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-12 col-xl-12 mg-b-10">
              <div className="media media-folder">
                <i className="fas fa-check-circle pd-5"></i>
                <div className="media-body">
                  <h6>
                    <a href="" className="link-02">
                      depositphotos-5g-mobilne-mreze-telekomunikacije-4.jpg
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-12 col-xl-12 mg-b-10">
              <div className="media media-folder">
                <i className="fas fa-times-circle pd-5"></i>
                <div className="media-body">
                  <h6>
                    <a href="" className="link-02">
                      depositphotos-5g-mobilne-mreze-telekomunikacije-4.jpg
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div> */}

          <hr className="mg-y-40 bd-0" />
          <label className="d-block tx-medium tx-10 tx-uppercase tx-sans tx-spacing-1 tx-color-03 mg-b-15">
            Datoteke
          </label>
          <div className="row row-xs">
            {files.map((file, index) => (
              <MediaManagerFile
                file={file}
                onDelete={() => handleDeleteFile(file)}
                onSelect={() => onFileSelect(file)}
                key={`file_${index}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaManagerContent;
