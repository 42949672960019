import { useMemo } from "react";
import _ from "lodash";

import { SwitchProps } from "./types";

const Switch: React.FC<SwitchProps> = ({ label, checked, onChange }) => {
  const id = useMemo(() => _.random(0, 99999).toString(), []);

  const handleChange = () => {
    if (onChange) {
      onChange(!checked);
    }
  };

  return (
    <div className="custom-control custom-switch">
      <input
        type="checkbox"
        className="custom-control-input"
        checked={checked}
        onChange={handleChange}
        id={id}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default Switch;
