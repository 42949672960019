import { useEffect, useState } from "react";

import { initialPagination2 } from "src/services/api/consts";
import { Pagination2 } from "src/services/api/types";
import { Form, getForms } from "src/services/forms";
import { ReactTable } from "src/ui/components/Table";

import { formColumns } from "./consts";

const FormsPage = () => {
  const [forms, setForms] = useState<Pagination2<Form>>(initialPagination2);
  const [isLoading, setIsLoading] = useState(false);

  const fetchForms = async (page: number, pageSize: number) => {
    setIsLoading(true);
    const resForms = await getForms(page, pageSize);
    setForms(resForms);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchForms(forms.meta.page, forms.meta.pageSize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaginationChange = async (page: number, pageSize: number) => {
    fetchForms(page, pageSize);
  };

  return (
    <ReactTable
      columns={formColumns}
      data={forms.data}
      onPaginationChange={handlePaginationChange}
      isLoading={isLoading}
      totalPages={forms.meta.totalPages}
    />
  );
};

export default FormsPage;
