import { StoreTypes } from "./consts";

const token = localStorage.getItem("token");

const initialStore = {
  username: null,
  token: token || null,
};

const userReducer = (_, action) => {
  switch (action.type) {
    case StoreTypes.LOGIN:
      localStorage.setItem("token", action.payload.token);

      return {
        username: action.payload.user.username,
        token: action.payload.token,
      };
    case StoreTypes.LOGOUT:
      localStorage.removeItem("token");

      return {
        username: null,
        token: null,
      };
    case StoreTypes.LOGIN_CHECK:
      const user = action.payload.user;
      if (user) {
        return {
          username: user.username,
          token: localStorage.getItem("token"),
        };
      }

      return {
        username: null,
        token: null,
      };
    default:
      return initialStore;
  }
};

export default userReducer;
