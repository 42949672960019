import * as yup from "yup";

// slides
export const getBlockSlideValidation = () => {
  const contentSchema = {
    title: yup.string().required("Naslov je obavezan"),
  };

  const blockSchema = {
    contents: yup.array().of(yup.object().shape(contentSchema)),
  };

  return yup.object().shape(blockSchema);
};

// featured
export const getBlocFeaturedValidation = () => {
  const contentSchema = {
    title: yup.string().required("Naslov je obavezan"),
  };

  const blockSchema = {
    contents: yup.array().of(yup.object().shape(contentSchema)),
  };

  return yup.object().shape(blockSchema);
};

// pinned items
export const getBlockItemsValidation = () => {
  const contentSchema = {
    title: yup.string().required("Naslov je obavezan"),
  };

  const blockSchema = {
    contents: yup.array().of(yup.object().shape(contentSchema)),
  };

  return yup.object().shape(blockSchema);
};
