import { apiCall } from "../api";

export const getBlocks = async (type) => {
  const res = await apiCall.get(`/api/v1/blocks?type=${type}`);

  return res.data;
};

export const getBlock = async (id) => {
  const res = await apiCall.get(`/api/v1/blocks/${id}`);

  return res.data;
};

export const postBlock = async (block) => {
  const res = await apiCall.post("/api/v1/blocks", block);

  return res.data;
};

export const putBlock = async (id, block) => {
  const res = await apiCall.put(`/api/v1/blocks/${id}`, block);

  return res.data;
};

export const deleteBlock = async (id) => {
  const res = await apiCall.delete(`/api/v1/blocks/${id}`);

  return res.data;
};

export const changeBlockOrder = async (id, direction) => {
  await apiCall.post(`/api/v1/blocks/change-order`, { id, direction });
};
