import { memo, useCallback } from "react";
import { ArrowLeft, ArrowRight } from "react-feather";

const pageSizes = [25, 50, 100];

const DataTable = ({
  columns,
  usePagination = true,
  data = [],
  RowComponent,
  onAction,
  page,
  setPage,
  FiltersComponent = null,
  meta = {},
  filters = {},
  setFilters,
  loading = false,
  pageSize = 25,
  setPageSize,
}) => {
  const handlePaginationPrevClicked = () => setPage(page - 1);

  const handlePaginationNextClicked = () => setPage(page + 1);

  const Row = useCallback(() => {
    if (loading) {
      return (
        <tr>
          <td colSpan={columns.length} align="center">
            <div className="text-center">
              <div className="spinner-border"></div>
            </div>
          </td>
        </tr>
      );
    }

    if (data.length === 0) {
      return (
        <tr>
          <td colSpan={columns.length} align="center">
            No data
          </td>
        </tr>
      );
    }

    return data.map((row, index) => (
      <RowComponent key={index} row={row} onAction={onAction} meta={meta} />
    ));
  }, [loading, data, onAction, meta, columns]);

  return (
    <div className="col-lg-12 col-xl-12">
      <div className="card">
        <div className="card-body pd-lg-25">
          <div className="d-flex justify-content-between mb-3">
            <div>
              <span className="mr-1">Broj rezultata po stranici: </span>
              {pageSizes.map((size, index) => (
                <button
                  key={`size_${index}`}
                  type="button"
                  className={`btn btn-xs mr-1 ${
                    pageSize === size ? "btn-secondary" : "btn-outline-light"
                  }`}
                  onClick={() => setPageSize(size)}
                >
                  {size}
                </button>
              ))}
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  {columns.map((c, index) => (
                    <th key={index} style={{ width: c.width || "initial" }}>
                      {c.title}
                    </th>
                  ))}
                </tr>
                {FiltersComponent && (
                  <FiltersComponent
                    meta={meta}
                    filters={filters}
                    setFilters={setFilters}
                  />
                )}
              </thead>
              <tbody>
                <Row />
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <div>Stranica 1 od 1</div>
              {usePagination && (
                <div>
                  <div className="btn-group" role="group" aria-label=".">
                    <button
                      type="button"
                      className="btn btn-outline-light"
                      onClick={handlePaginationPrevClicked}
                      disabled={page === 1}
                    >
                      <ArrowLeft />
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={handlePaginationPrevClicked}
                      disabled={page === 1}
                    >
                      1
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-light"
                      onClick={handlePaginationNextClicked}
                    >
                      <ArrowRight />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(DataTable);
