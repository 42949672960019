import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";

import "react-datetime/css/react-datetime.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-sortable-tree/style.css";
import "./assets/css/dashforge.css";
import "./assets/css/dashforge.filemgr.css";
import "./assets/css/skin.light.css";
import "./assets/css/skin.charcoal.css";
import "./assets/fontawesome/fontawesome-free/css/all.min.css";
import "./assets/ionicons/css/ionicons.min.css";
import "./assets/typicons.font/typicons.css";
import "./assets/css/custom.css";

import App from "./App";
import configureStore from "./app/redux/store";

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ToastProvider autoDismiss>
      <App />
    </ToastProvider>
  </Provider>,
  document.getElementById("root")
);
