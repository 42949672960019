import { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import { ArrowDown, ArrowUp, Plus, Trash } from "react-feather";
import { Link } from "react-router-dom";

import { changeBlockOrder, deleteBlock, getBlocks } from "src/services/blocks";

const BlockFooterLink = () => {
  const [blocks, setBlocks] = useState([]);

  useEffect(() => {
    fetchBlocks();
  }, []);

  const fetchBlocks = async () => {
    const resBlocks = await getBlocks("footerlink");
    setBlocks(resBlocks.data);
  };

  const handleDeleteClicked = (id) => () => {
    confirmAlert({
      title: "Brisanje bloka",
      message: "Jeste li sigurni da želite obrisati blok?",
      buttons: [
        {
          label: "Da",
          onClick: async () => {
            await deleteBlock(id);
            fetchBlocks();
          },
        },
        {
          label: "Ne",
          onClick: () => {},
        },
      ],
    });
  };

  const handleChangeOrder = (id, direction) => async () => {
    await changeBlockOrder(id, direction);
    fetchBlocks();
  };

  return (
    <div className="col-lg-12 col-xl-12">
      <div className="card">
        <div className="card-body pd-lg-25">
          <div className="row">
            <div className="col-lg-2">
              <div className="btn-group mg-t-20 mg-sm-t-0 mg-b-10">
                <Link
                  to="/blocks/footer-link/create"
                  className="btn btn-s btn-success btn-icon"
                >
                  <Plus /> Dodaj stavku
                </Link>
              </div>
            </div>
            {/* <div className="col-lg-2">
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Broj objava za prikaz"
                  aria-label="Broj objava za prikaz"
                  aria-describedby="basic-addon2"
                />
                <InputGroup.Append>
                  <Button variant="outline-secondary">Spremi</Button>
                </InputGroup.Append>
              </InputGroup>
            </div> */}
          </div>

          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr style={{ backgroundColor: "#eee" }}>
                  <th style={{ width: "60px" }}>Redoslijed</th>
                  <th>Naslov</th>
                  <th>Akcije</th>
                </tr>
              </thead>
              <tbody>
                {blocks.map((block, index) => (
                  <tr key={index}>
                    <td className="text-center">
                      {index > 0 && (
                        <button
                          type="button"
                          className="btn btn-xs btn-secondary btn-icon mb-1"
                          style={{ width: "50px" }}
                          onClick={handleChangeOrder(block.id, "up")}
                        >
                          <ArrowUp />
                        </button>
                      )}
                      {index < blocks.length - 1 && (
                        <button
                          type="button"
                          className="btn btn-xs btn-secondary btn-icon mb-1"
                          style={{ width: "50px" }}
                          onClick={handleChangeOrder(block.id, "down")}
                        >
                          <ArrowDown />
                        </button>
                      )}
                    </td>
                    <td style={{ width: "1200px" }}>
                      <Link to={`/blocks/footer-link/${block.id}/edit`}>
                        {block.content.title}
                      </Link>
                    </td>

                    <td style={{ width: "110px" }}>
                      <button
                        type="button"
                        className="btn btn-xs btn-danger btn-icon mb-1"
                        style={{ width: "110px" }}
                        onClick={handleDeleteClicked(block.id)}
                      >
                        <Trash style={{ height: "12px", marginTop: "-4px" }} />{" "}
                        Briši
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockFooterLink;
