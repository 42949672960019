import { initialBlock, initialBlockContnet } from "./consts";

export const transformToInitialBlock = (langCodes) => {
  return {
    ...initialBlock,
    contents: [
      ...langCodes.map((code) => ({
        ...initialBlockContnet,
        language_code: code,
      })),
    ],
  };
};
