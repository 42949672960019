import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ActionCreators } from "src/app/redux/actions";
import { apiCall } from "src/services/api";

const loginUser = {
  username: null,
  password: null,
};

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [user, setUser] = useState(loginUser);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleLogInClicked = async () => {
    setLoading(true);
    setError(false);

    let token = null;
    try {
      const { data } = await apiCall.post("/api/auth/signin", user);
      token = data.token;
    } catch (e) {
      setLoading(false);
      setError(true);
      return;
    }

    dispatch(
      ActionCreators.loginUser({ id: 1, username: user.username }, token)
    );

    history.push("/");
  };

  const handleTextFieldChange = (e) => {
    setUser((old) => ({ ...old, [e.target.name]: e.target.value || null }));
  };

  const handleTextFieldKeyDown = (e) => {
    // enter key
    if (e.keyCode === 13) {
      handleLogInClicked();
    }
  };

  return (
    <div className="content content-fixed content-auth">
      <div className="container">
        <div className="media align-items-stretch justify-content-center ht-100p pos-relative">
          <div className="sign-wrapper mg-lg-l-50 mg-xl-l-60">
            <div className="wd-100p">
              <h3 className="tx-color-01 mg-b-5">CMS</h3>
              <p className="tx-color-03 tx-16 mg-b-40">
                Dobrodošli! Prijavite se da biste nastavili.
              </p>
              <div className="form-group">
                <label>Korisničko ime</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Upišite korisničko ime"
                  name="username"
                  onChange={handleTextFieldChange}
                  onKeyDown={handleTextFieldKeyDown}
                />
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between mg-b-5">
                  <label className="mg-b-0-f">Lozinka</label>
                </div>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Upišite lozinku"
                  name="password"
                  onChange={handleTextFieldChange}
                  onKeyDown={handleTextFieldKeyDown}
                />
              </div>
              <button
                className="btn btn-brand-02 btn-block"
                onClick={handleLogInClicked}
                disabled={loading}
              >
                {loading && (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                  </>
                )}
                Prijava
              </button>
              {error && (
                <div className="alert alert-danger mg-t-20" role="alert">
                  Pogrešno korisničko ime ili lozinka
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
