import * as yup from "yup";

export const getUserValidation = (userId) => {
  const schema = {
    first_name: yup.string().required("Ime je obavezno"),
    last_name: yup.string().required("Prezime je obavezno"),
    username: yup.string().required("Korisničko ime je obavezno"),
    email: yup
      .string()
      .email("Neispravna e-mail adresa")
      .required("E-mail je obavezan"),
  };

  if (!userId) {
    schema.password = yup.string().required("Lozinka je obavezna");
  }

  return yup.object().shape(schema);
};
