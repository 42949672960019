import { Link } from "react-router-dom";
import { Column } from "react-table";

import { UserGroup } from "src/services/userGroups";

export const userGroupColumns: Column<UserGroup>[] = [
  {
    Header: "Naziv",
    accessor: "name",
    Cell: ({ row }) => {
      return (
        <Link to={`/user-groups/${row.original.id}/edit`}>
          {row.original.name}
        </Link>
      );
    },
  },
  {
    Header: "",
    accessor: "id",
    Cell: () => {
      return null;
    },
  },
];
