import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { ActionCreators } from "src/app/redux/actions";

import AppRouter from "./app/router/AppRouter";
import { TopNavigation, LeftNavigation } from "./app/components";
import { isUserLogedIn } from "./app/redux/selectors";
import { apiCall } from "./services/api";

const token = localStorage.getItem("token");

function App() {
  const dispatch = useDispatch();
  const logedIn = useSelector(isUserLogedIn);

  apiCall.defaults.headers.common["Authorization"] = logedIn
    ? `Bearer ${localStorage.getItem("token")}`
    : null;

  apiCall.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.response !== undefined &&
        token &&
        error.response.status === 401
      ) {
        dispatch(ActionCreators.logoutUser());
      }

      return Promise.reject(error);
    }
  );

  return (
    <Router>
      {!logedIn ? (
        <AppRouter />
      ) : (
        <>
          <LeftNavigation />
          <div className="content ht-100v pd-0">
            <TopNavigation />
            <div className="content-body">
              <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                  {/* <Breadcrumbs /> */}
                  <div className="d-none d-md-block"></div>
                </div>
                <div className="row row-xs">
                  <AppRouter />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="modal-backdrop fade"></div> */}
        </>
      )}
    </Router>
  );
}

export default App;
