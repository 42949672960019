import { apiCall } from "../api";

export const getArticles = async (page, pageSize, filters = {}) => {
  let url = `/api/v1/articles?lang=hr&page=${page}&pageSize=${pageSize}`;

  const filterParams = Object.keys(filters).reduce((acc, filterKey) => {
    if (!filters[filterKey]) {
      return acc;
    }

    return `${acc}&${filterKey}=${filters[filterKey]}`;
  }, "");

  if (filterParams) {
    url += `&${filterParams}`;
  }

  const res = await apiCall.get(url);

  return res.data;
};

export const getArticle = async (id) => {
  const res = await apiCall.get(`/api/v1/articles/${id}`);

  return res.data;
};

export const postArticle = async (article) => {
  const res = await apiCall.post("/api/v1/articles", article);

  return res.data;
};

export const putArticle = async (id, article) => {
  // omit nextArticle and previousArticle
  const { nextArticle, previousArticle, ...data } = article;

  const res = await apiCall.put(`/api/v1/articles/${id}`, data);

  return res.data;
};

export const getArticleList = async () => {
  const res = await apiCall.get(`/api/v1/articles/list`);

  return res.data.data;
};

export const deleteArticle = async (id) => {
  await apiCall.delete(`/api/v1/articles/${id}`);
};

export const uploadMainImage = async (image) => {
  const formData = new FormData();
  formData.append("main_image", image);

  const res = await apiCall.post(
    `/api/v1/articles/upload-main-image`,
    formData
  );

  return res.data;
};

export const toggleActive = async (id) => {
  const res = await apiCall.post(`/api/v1/articles/toggle-active`, { id });

  return res.data;
};
