import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Save, Trash } from "react-feather";
import { useHistory, useParams } from "react-router";
import Select from "react-select";
import { useToasts } from "react-toast-notifications";
import { initialPagination, Pagination } from "src/services/api";

import { useUserGroup } from "src/services/userGroups/hooks";
import { User } from "src/services/users";
import { getUsers } from "src/services/users/api";
import { TextField } from "src/ui/components/TextField";

const UserGroupForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const { id: activeId } = useParams<{ id: string | undefined }>();
  const {
    setActiveId,
    onSave,
    userGroup,
    setUserGroup,
    errors,
    isLoading,
  } = useUserGroup();

  const [users, setUsers] = useState<Pagination<User>>(initialPagination);

  useEffect(() => {
    if (activeId) {
      setActiveId(parseInt(activeId));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId]);

  useEffect(() => {
    (async () => {
      try {
        const resUsers = await getUsers();
        setUsers(resUsers);
      } catch {}
    })();
  }, []);

  const handleSaveClicked = async () => {
    try {
      await onSave();
    } catch {
      return;
    }

    history.push("/user-groups");
    addToast(activeId ? "Grupa je uređena" : "Dodana je nova grupa", {
      appearance: "success",
    });
  };

  const handleTextFieldChange = (e: any) => {
    setUserGroup((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  const handleRadioChange = (e: any) => {
    setUserGroup((old) => ({ ...old, active: e.target.value === "true" }));
  };

  const handleUsersChange = (selectedUsers: any) => {
    setUserGroup((old) => ({ ...old, users: selectedUsers as User[] }));
  };
  // const spinner = {
  //   paddingTop: "233px",
  //   paddingBottom: "233px",
  //   margin: "auto",
  // };

  return (
    <Col xs={12}>
      <Card>
        {/* spinner */}
        {/* <div className="text-center" style={spinner}>
          <div className="spinner-border"></div>
        </div> */}
        {/* spinner end */}
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <h6>{!!activeId ? "Uređivanje grupe" : "Nova grupa"}</h6>
            <div>
              <Button
                size="sm"
                variant="primary"
                onClick={handleSaveClicked}
                disabled={isLoading}
              >
                <Save /> Spremi
              </Button>
              {!!activeId && (
                <Button
                  size="sm"
                  variant="outline-danger"
                  className="mg-l-4"
                  disabled={isLoading}
                >
                  <Trash />
                  Obriši
                </Button>
              )}
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12}>
              <TextField
                type="text"
                label="Naziv grupe"
                name="name"
                placeholder="Upiši naziv grupe"
                value={userGroup.name}
                onChange={handleTextFieldChange}
                error={errors.name}
                hasError={!!errors.name}
              />
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Status grupe</Form.Label>
                <div key="inline-checkbox">
                  <Form.Check
                    inline
                    label="Aktivna"
                    value="true"
                    type="radio"
                    onChange={handleRadioChange}
                    checked={userGroup.active}
                    id="status-active"
                  />
                  <Form.Check
                    inline
                    label="Neaktivna"
                    value="false"
                    type="radio"
                    checked={!userGroup.active}
                    onChange={handleRadioChange}
                    id="status-inactive"
                  />
                </div>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Korisnici</Form.Label>
                <Select
                  options={users.data}
                  placeholder="Odaberite korisnike"
                  isMulti
                  isSearchable
                  formatOptionLabel={(c: User) =>
                    `${c.first_name} ${c.last_name}`
                  }
                  getOptionValue={(c: any) => c.id}
                  getOptionLabel={(c: User) => `${c.first_name} ${c.last_name}`}
                  onChange={handleUsersChange}
                  value={userGroup.users}
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group controlId="notes">
                <Form.Label>Napomena</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Unesite napomenu"
                  name="notes"
                  onChange={handleTextFieldChange}
                  value={userGroup.notes}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default UserGroupForm;
