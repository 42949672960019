import { initialCategory } from "./consts";

export const transformToInitialCategory = (langCodes) => {
  return {
    ...initialCategory,
    contents: [
      ...langCodes.map((code) => ({
        language_code: code,
        name: "",
        slug: "",
        meta: "",
      })),
    ],
  };
};

export const transformToCategory = (res) => {
  return {
    ...res,
    parent_id: res.parent_id ? parseInt(res.parent_id, 10) : null,
    template_id: res.template_id ? parseInt(res.template_id, 10) : null,
    order: res.order || "desc",
  };
};
