export const initialBlock = {
  image: null,
  type: "",
  contents: [],
};

export const initialBlockContnet = {
  title: "",
  content: "",
  langauge_code: "",
  url: "",
};
