import { useEffect, useState } from "react";
import { Card, Col } from "react-bootstrap";
import { Trash } from "react-feather";
import { Link } from "react-router-dom";
import SortableTree from "react-sortable-tree";
import { confirmAlert } from "react-confirm-alert";
import { useToasts } from "react-toast-notifications";

import {
  deleteCategory,
  getCategoriesTree,
  updateCategoriesTree,
} from "src/services/categories";

const CategoriesPage = () => {
  const { addToast } = useToasts();

  const [tree, setTree] = useState([]);

  useEffect(() => {
    (async () => {
      const resTree = await getCategoriesTree();
      setTree(resTree);
    })();
  }, []);

  const handleTreeChange = (newTree) => {
    updateCategoriesTree(newTree);
    setTree(newTree);
  };

  const handleDeleteClicked = (id) => () => {
    confirmAlert({
      title: "Brisanje kategorije",
      message:
        "Jeste li sigurni da želite obrisati kategoriju? Sve kategorije koje se nalaze unutar nje će također biti obrisane.",
      buttons: [
        {
          label: "Da",
          onClick: async () => {
            await deleteCategory(id);
            const resTree = await getCategoriesTree();
            setTree(resTree);
            addToast("Kategorija je obrisana", { appearance: "success" });
          },
        },
        {
          label: "Ne",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <Col md={12}>
      <Card>
        <Card.Header>
          <b>Kategorije</b>
        </Card.Header>
        <Card.Body>
          <SortableTree
            treeData={tree}
            onChange={handleTreeChange}
            isVirtualized={false}
            generateNodeProps={({ node }) => {
              return {
                buttons: [
                  <button
                    className="btn btn-danger btn-xs"
                    onClick={handleDeleteClicked(node.id)}
                  >
                    <Trash />
                  </button>,
                ],
                title: (
                  <Link to={`/categories/${node.id}/edit`}>{node.name}</Link>
                ),
              };
            }}
          />
        </Card.Body>
      </Card>
    </Col>
  );
};

export default CategoriesPage;
