import * as yup from "yup";
import _ from "lodash";

export const validateYup = (
  fields: object,
  schema: yup.ObjectSchema<any>,
  setErrors: (errors: any) => void
): boolean => {
  let errors: {} = {};

  try {
    schema.validateSync(fields, { abortEarly: false });
  } catch (err) {
    errors = err.inner.reduce(
      (acc: any, e: any) => ({ ...acc, [e.path]: e.message }),
      {}
    );
  }

  setErrors(errors);

  return _.isEmpty(errors);
};
