import { apiCall, transformToPagination } from "../api";
import {
  transformToUserGroup,
  transformToUserGroupRequest,
} from "./transformations";
import { UserGroup } from "./types";

export const getUserGroups = async () => {
  const res = await apiCall.get("/api/v1/user-groups");

  return transformToPagination(res.data, transformToUserGroup);
};

export const getUserGroup = async (id: number) => {
  const res = await apiCall.get(`/api/v1/user-groups/${id}`);

  return transformToUserGroup(res.data);
};

export const postUserGroup = async (userGroup: UserGroup) => {
  const res = await apiCall.post(
    "/api/v1/user-groups",
    transformToUserGroupRequest(userGroup)
  );

  return transformToUserGroup(res.data);
};

export const putUserGroup = async (userGroup: UserGroup) => {
  const res = await apiCall.put(
    `/api/v1/user-groups/${userGroup.id}`,
    transformToUserGroupRequest(userGroup)
  );

  return transformToUserGroup(res.data);
};

export const deleteUserGroup = async (id: number) => {
  await apiCall.delete(`/api/v1/user-groups/${id}`);
};
