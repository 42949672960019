import React from "react";

import { TextFieldProps } from "./types";

const TextField: React.FC<TextFieldProps> = ({
  hasError,
  error,
  label,
  ...otherProps
}) => {
  return (
    <div className="form-group">
      {!!label && <label htmlFor={otherProps.id}>{label}</label>}
      <input
        className={`form-control ${hasError ? "is-invalid" : ""}`}
        {...otherProps}
      />
      {hasError && error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default TextField;
