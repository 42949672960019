import { apiCall } from "src/services/api";

import { StoreTypes } from "./consts";

const token = localStorage.getItem("token");

export const ActionCreators = {
  loginUser: (user, token) => {
    return {
      type: StoreTypes.LOGIN,
      payload: { user, token },
    };
  },
  logoutUser: () => {
    apiCall.post("/api/auth/signout");

    return { type: StoreTypes.LOGOUT, payload: null };
  },
  loginCheck: () => {
    return async function (dispatch) {
      if (!token) {
        dispatch({ type: StoreTypes.LOGIN_CHECK, payload: { user: null } });
      }

      try {
        const { data } = await apiCall.get("/api/auth/me");

        dispatch({ type: StoreTypes.LOGIN_CHECK, payload: { user: data } });
      } catch {
        dispatch({ type: StoreTypes.LOGIN_CHECK, payload: { user: null } });
      }
    };
  },
};
