import { useCallback, useEffect, useState } from "react";

import { validateYup } from "../validations";
import { getUserGroup, postUserGroup, putUserGroup } from "./api";
import { initialUserGroup } from "./consts";
import { UserGroup } from "./types";
import { userGroupValidation } from "./validations";

export const useUserGroup = (id?: number | null) => {
  const [activeId, setActiveId] = useState<number | null>(id || null);
  const [userGroup, setUserGroup] = useState<UserGroup>(initialUserGroup);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (!activeId) return;

    (async () => {
      setIsLoading(true);
      try {
        const resUserGroup = await getUserGroup(activeId);
        setUserGroup(resUserGroup);
      } catch (e) {
        console.error(e);
      }
      setIsLoading(false);
    })();
  }, [activeId]);

  const onSave = useCallback(async () => {
    if (!validateYup(userGroup, userGroupValidation, setErrors)) {
      throw new Error("Validation error");
    }

    setIsLoading(true);
    if (userGroup.id < 0) {
      try {
        const res = await postUserGroup(userGroup);
        setUserGroup(res);
      } catch (e) {
        console.error(e);
        throw e;
      }
    } else {
      try {
        const res = await putUserGroup(userGroup);
        setUserGroup(res);
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
    setIsLoading(false);
  }, [userGroup]);

  return {
    userGroup,
    setUserGroup,
    setActiveId,
    isLoading,
    setIsLoading,
    onSave,
    errors,
  };
};
