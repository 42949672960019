import { Pagination, Pagination2 } from "./types";

export const initialPagination: Pagination<any> = {
  data: [],
  meta: {
    current_page: 1,
    last_page: 1,
    per_page: 1,
    total: 1,
  },
};

export const initialPagination2: Pagination2<any> = {
  data: [],
  meta: {
    count: 0,
    page: 1,
    pageSize: 25,
    total: 1,
    totalPages: 1,
  },
};
