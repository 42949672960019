import { TextField } from "src/ui/components/TextField";

const GroupsForm = () => {
  return (
    <>
      <div className="col-lg-8 col-xl-9 mg-b-50">
        <div className="card">
          <div className="card-header">
            <h6 className="mg-b-0">
              Profil grupe <span className="mg-r-20"></span>{" "}
              <button
                type="button"
                className="btn btn-xs btn-outline-secondary"
              >
                <i className="fas fa-save"></i> Spremi
              </button>
              <button
                type="button"
                className="btn btn-xs btn-outline-secondary"
              >
                <i className="fas fa-times"></i> Obriši
              </button>
            </h6>
          </div>

          <div className="card-body pd-lg-25">
            <TextField
              type="text"
              label="Naziv grupe"
              name="group_name"
              placeholder="Upiši naziv grupe"
            />

            <h6 className="mg-b-10 mg-t-25">Status grupe</h6>
            <div className="row pd-l-15 mg-b-10">
              <div className="custom-control custom-radio  mg-r-70 mg-b-10">
                <input
                  type="radio"
                  id="active"
                  name="group_status"
                  className="custom-control-input"
                  // checked
                />
                <label className="custom-control-label" htmlFor="active">
                  Aktivna
                </label>
              </div>

              <div className="custom-control custom-radio mg-b-10">
                <input
                  type="radio"
                  id="inactive"
                  name="user_status"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="inactive">
                  Neaktivna
                </label>
              </div>
            </div>

            <h6 className="mg-b-10">Korisnici</h6>
            <div className="mg-b-10">
              <select
                className="form-control select2"
                style={{ width: "100%" }}
                multiple
                placeholder="Odaberi grupe"
              >
                <option label="Odabir grupa" disabled></option>
                <option value="1">Grupa 1</option>
                <option value="2">Grupa 2</option>
                <option value="3">Grupa 3</option>
                <option value="4">Grupa 4</option>
                <option value="5">Grupa 5</option>
              </select>
            </div>

            <h6 className="mg-b-10">Napomena</h6>
            <div className="mg-b-10">
              <textarea
                className="form-control"
                rows="2"
                placeholder=""
              ></textarea>
            </div>

            <hr />
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-4 col-xl-3 mg-t-10 mg-lg-t-0 mg-b-50">
        <div className="card">

          {/* spinner */}
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>

          {/* nakon loada se učita ovaj dio */}
          <div className="card-header">
            <h6 className="mg-b-0">Ostale postavke</h6>
          </div>

          <div className="card-body pd-lg-25">
            <h6 className="mg-b-5">Profilna slika</h6>
            <div className="custom-file mg-b-10">
              <button
                type="button"
                className="btn btn-xs btn-outline-secondary"
                // onclick="window.location.href='#mediamanager'"
              >
                <i className="far fa-image"></i> Odaberi
              </button>
              <button
                type="button"
                className="btn btn-xs btn-outline-secondary"
              >
                <i className="fas fa-times"></i> Poništi
              </button>
            </div>
            <div className="mg-b-25">
              <img
                src="media/dummy/glavna-slika-clanak.jpg"
                className="img-fluid"
                alt="Responsive"
              />
              <small>Preporučena dimenzija: 1200px x 800px i više</small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupsForm;
