import { UserGroup } from "./types";

export const transformToUserGroup = (res: any): UserGroup => {
  return {
    id: res.id,
    name: res.name,
    users: res.users,
    notes: res.notes,
    active: res.active,
  };
};

export const transformToUserGroupRequest = (userGroup: UserGroup): any => {
  return {
    name: userGroup.name,
    users: userGroup.users.map((u) => u.id),
    notes: userGroup.notes,
    active: userGroup.active,
  };
};
