import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { LogOut } from "react-feather";

import { ActionCreators } from "src/app/redux/actions";
import avatar from "src/assets/img/avatar.png";

const TopNavigation = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showProfile, setShowProfile] = useState(false);

  const handleShowProfileClicked = (e) => {
    e.preventDefault();
    setShowProfile(!showProfile);
  };

  const handleLogoutClicked = (e) => {
    e.preventDefault();
    history.push("/login");
    dispatch(ActionCreators.logoutUser());
  };

  return (
    <div className="content-header">
      <div className="content-search"></div>
      <nav className="nav">
        <div className="dropdown dropdown-profile">
          <a
            href="/"
            className="dropdown-link"
            data-toggle="dropdown"
            data-display="static"
            aria-expanded="false"
            onClick={handleShowProfileClicked}
          >
            <div className="avatar avatar-sm">
              <img src={avatar} className="rounded-circle" alt="Avatar" />
            </div>
          </a>
          <div
            className={`dropdown-menu dropdown-menu-right tx-13 ${
              showProfile ? "show" : ""
            }`}
          >
            <div className="avatar avatar-lg mg-b-15">
              <img src={avatar} className="rounded-circle" alt="" />
            </div>
            <h6 className="tx-semibold mg-b-5">Username</h6>
            <p className="mg-b-25 tx-12 tx-color-03">Administrator</p>
            <a href="/" className="dropdown-item" onClick={handleLogoutClicked}>
              <LogOut /> Sign Out
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default TopNavigation;
