import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { getUser, initialUser, postUser, putUser } from "src/services/users";
import { TextField } from "src/ui/components/TextField";

import { getUserValidation } from "./validations";

const UsersForm = () => {
  const history = useHistory();
  const { id: userId } = useParams();
  const { addToast } = useToasts();

  const [user, setUser] = useState(initialUser);
  const [errors, setErrors] = useState({});

  // fetching user
  useEffect(() => {
    if (!userId) {
      setUser(initialUser);
      return;
    }

    (async () => {
      const resUser = await getUser(userId);
      setUser(resUser);
    })();
  }, [userId]);

  const handleTextFieldChange = (e) =>
    setUser({ ...user, [e.target.name]: e.target.value });

  const handleSubmitClicked = async () => {
    setErrors({});

    let successMsg = "";

    // validation
    try {
      await getUserValidation(userId).validate(user, { abortEarly: false });
    } catch (err) {
      setErrors(
        err.inner.reduce((acc, e) => ({ ...acc, [e.path]: e.message }), {})
      );
      return;
    }

    if (userId) {
      try {
        await putUser(userId, user);
      } catch {
        alert("we need to handle this error");
        return;
      }

      successMsg = "Korisnik je uređen";
    } else {
      try {
        await postUser(user);
      } catch {
        alert("we need to handle this error");
        return;
      }

      successMsg = "Dodan je novi korisnik";
    }

    history.push("/users");

    addToast(successMsg, { appearance: "success" });
  };

  return (
    <>
      <div className="col-lg-8 col-xl-9 mg-b-50">
        <div className="card">
          <div className="card-header">
            <h6 className="mg-b-0">
              Profil korisnika (Ime) (Prezime) <span className="mg-r-20"></span>{" "}
              <button
                type="button"
                className="btn btn-xs btn-outline-secondary"
                onClick={handleSubmitClicked}
              >
                <i className="fas fa-save"></i> Spremi
              </button>
              <button
                type="button"
                className="btn btn-xs btn-outline-secondary"
              >
                <i className="fas fa-times"></i> Obriši
              </button>
            </h6>
          </div>

          <div className="card-body pd-lg-25">
            <TextField
              type="text"
              label="Ime"
              name="first_name"
              value={user.first_name}
              onChange={handleTextFieldChange}
              placeholder="Upiši ime"
              hasError={!!errors.first_name}
              error={errors.first_name}
            />
            <TextField
              type="text"
              label="Prezime"
              name="last_name"
              value={user.last_name}
              onChange={handleTextFieldChange}
              placeholder="Upiši prezime"
              hasError={!!errors.last_name}
              error={errors.last_name}
            />
            <TextField
              type="email"
              label="E-mail"
              placeholder="Upiši e-mail"
              name="email"
              value={user.email}
              onChange={handleTextFieldChange}
              hasError={!!errors.email}
              error={errors.email}
            />
            <TextField
              type="text"
              label="Korisničko ime"
              name="username"
              value={user.username}
              onChange={handleTextFieldChange}
              placeholder="Upiši korisničko ime"
              hasError={!!errors.username}
              error={errors.username}
            />
            <TextField
              type="text"
              label="Lozinka"
              placeholder="Upiši lozinku"
              name="password"
              value={user.password}
              onChange={handleTextFieldChange}
              hasError={!!errors.password}
              error={errors.password}
            />

            <h6 className="mg-b-10 mg-t-25">Tip korisnika</h6>
            <div className="row pd-l-15 mg-b-10">
              <div className="custom-control custom-radio  mg-r-70 mg-b-10">
                <input
                  type="radio"
                  id="user"
                  name="user_type"
                  className="custom-control-input"
                  // checked
                />
                <label className="custom-control-label" htmlFor="user">
                  Korisnik
                </label>
              </div>

              <div className="custom-control custom-radio mg-b-10">
                <input
                  type="radio"
                  id="admin"
                  name="user_type"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="admin">
                  Administrator
                </label>
              </div>
            </div>

            <h6 className="mg-b-10 mg-t-25">Status korisnika</h6>
            <div className="row pd-l-15 mg-b-10">
              <div className="custom-control custom-radio  mg-r-70 mg-b-10">
                <input
                  type="radio"
                  id="active"
                  name="user_status"
                  className="custom-control-input"
                  // checked
                />
                <label className="custom-control-label" htmlFor="active">
                  Aktivan
                </label>
              </div>

              <div className="custom-control custom-radio mg-b-10">
                <input
                  type="radio"
                  id="inactive"
                  name="user_status"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="inactive">
                  Neaktivan
                </label>
              </div>
            </div>

            <h6 className="mg-b-10">Grupe</h6>
            <div className="mg-b-10">
              <select
                className="form-control select2"
                style={{ width: "100%" }}
                multiple
                placeholder="Odaberi grupe"
              >
                <option label="Odabir grupa" disabled></option>
                <option value="1">Grupa 1</option>
                <option value="2">Grupa 2</option>
                <option value="3">Grupa 3</option>
                <option value="4">Grupa 4</option>
                <option value="5">Grupa 5</option>
              </select>
            </div>

            <h6 className="mg-b-10">Napomena</h6>
            <div className="mg-b-10">
              <textarea
                className="form-control"
                rows="2"
                placeholder=""
              ></textarea>
            </div>

            <hr />
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-4 col-xl-3 mg-t-10 mg-lg-t-0 mg-b-50">
        <div className="card">
          <div className="card-header">
            <h6 className="mg-b-0">Ostale postavke</h6>
          </div>

          <div className="card-body pd-lg-25">
            <h6 className="mg-b-5">Profilna slika</h6>
            <div className="custom-file mg-b-10">
              <button
                type="button"
                className="btn btn-xs btn-outline-secondary"
                // onclick="window.location.href='#mediamanager'"
              >
                <i className="far fa-image"></i> Odaberi
              </button>
              <button
                type="button"
                className="btn btn-xs btn-outline-secondary"
              >
                <i className="fas fa-times"></i> Poništi
              </button>
            </div>
            <div className="mg-b-25">
              <img
                src="media/dummy/glavna-slika-clanak.jpg"
                className="img-fluid"
                alt="Responsive"
              />
              <small>Preporučena dimenzija: 1200px x 800px i više</small>
            </div>

            <h6 className="mg-b-10">Potpis</h6>
            <div className="mg-b-10">
              <textarea
                className="form-control"
                rows="2"
                placeholder=""
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersForm;
