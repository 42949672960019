import React, { useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import { leftNavigation } from "./consts";

export default function LeftNavigation() {
  const [openedItems, setOpenedItems] = useState([]);

  const handleItemClicked = (key) => (e) => {
    e.preventDefault();
    const index = openedItems.findIndex((i) => i === key);
    if (index === -1) {
      setOpenedItems([...openedItems, key]);
    } else {
      setOpenedItems([
        ...openedItems.slice(0, index),
        ...openedItems.slice(index + 1),
      ]);
    }
  };

  return (
    <>
      <aside className="aside aside-fixed">
        <div className="aside-header">
          <Link to="/" className="aside-logo">
            W.CMS
          </Link>
          <a href="" className="aside-menu-link">
            {/* <i className="fas fa-bars"></i>
            <i className="fas fa-times"></i> */}
          </a>
        </div>
        <div className="aside-body ps">
          <div className="aside-loggedin">
            <ul className="nav nav-aside">
              {leftNavigation.map((group, groupKey) => (
                <React.Fragment key={groupKey}>
                  <li className={`nav-label ${groupKey > 0 ? "mg-t-25" : ""}`}>
                    {group.label}
                  </li>
                  {!_.isEmpty(group.items) &&
                    group.items.map((item, itemKey) =>
                      _.isEmpty(item.subItems) ? (
                        <li className="nav-item" key={`${groupKey}-${itemKey}`}>
                          <Link to={item.url} className="nav-link">
                            {!!item.icon && item.icon} <span>{item.label}</span>
                          </Link>
                        </li>
                      ) : (
                        <li
                          className={`nav-item with-sub ${
                            openedItems.includes(groupKey + "-" + itemKey)
                              ? "show"
                              : ""
                          }`}
                          key={`${groupKey}-${itemKey}`}
                        >
                          <a
                            href="#"
                            className="nav-link"
                            onClick={handleItemClicked(
                              `${groupKey}-${itemKey}`
                            )}
                          >
                            {!!item.icon && item.icon} <span>{item.label}</span>
                          </a>
                          <ul>
                            {item.subItems.map((subItem, subItemKey) =>
                              subItem.hidden === true ? null : (
                                <li
                                  key={`${groupKey}-${itemKey}-${subItemKey}`}
                                >
                                  <Link to={subItem.url} className="">
                                    <span>{subItem.label}</span>
                                  </Link>
                                </li>
                              )
                            )}
                          </ul>
                        </li>
                      )
                    )}
                </React.Fragment>
              ))}
            </ul>
          </div>
        </div>
      </aside>
    </>
  );
}
