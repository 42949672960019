import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import { useToasts } from "react-toast-notifications";
import Select from "react-select";
import { ExternalLink, Plus, Save, Trash } from "react-feather";

import { MAIN_LANGUAGE, USED_LANGUAGES } from "src/consts";
import {
  getCategory,
  getCategoryList,
  postCategory,
  putCategory,
  transformToInitialCategory,
} from "src/services/categories";
import { TextField } from "src/ui/components/TextField";
import { getTemplates } from "src/services/templates";

import { getCategoryValidation } from "./validations";
import { TinyMce } from "src/ui/components/TinyMce";
import { ImageForm } from "src/ui/components/ImageForm";

const viewContentOptions = [
  { value: "articles", label: "Ispiši članke" },
  { value: "categories", label: "Ispiši kategorije" },
];

const CategoriesForm = () => {
  const { id: activeId } = useParams();
  const history = useHistory();
  const { addToast } = useToasts();

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(
    transformToInitialCategory(USED_LANGUAGES)
  );
  const [activeLang, setActiveLang] = useState(MAIN_LANGUAGE);
  const [errors, setErrors] = useState({});
  const [templates, setTemplates] = useState([]);

  const selectedCategory = useMemo(
    () => categories.find((c) => c.id === category.parent_id) || null,
    [categories, category.parent_id]
  );

  const selectedTemplate = useMemo(
    () => templates.find((t) => t.id === category.template_id) || null,
    [templates, category.template_id]
  );

  const selectedViewContent = useMemo(
    () =>
      viewContentOptions.find((o) => o.value === category.content_view) || null,
    [category.content_view]
  );

  // fetch category
  useEffect(() => {
    if (!activeId) {
      setCategory(transformToInitialCategory(USED_LANGUAGES));
      return;
    }

    (async () => {
      try {
        const resCategory = await getCategory(activeId);
        setCategory(resCategory);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [activeId]);

  // fetch other data
  useEffect(() => {
    (async () => {
      try {
        const resCategories = await getCategoryList();
        setCategories(resCategories);
      } catch (e) {
        console.error(e);
      }

      try {
        const resTemplates = await getTemplates("category");
        setTemplates(resTemplates.data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const handleLangClicked = (lang) => (e) => {
    e.preventDefault();
    setActiveLang(lang);
  };

  const handleContentChange = (lang) => (e) => {
    const contentIndex = category.contents.findIndex(
      (c) => c.language_code === lang
    );

    if (contentIndex === -1) {
      return;
    }

    setCategory((old) => ({
      ...old,
      contents: [
        ...old.contents.slice(0, contentIndex),
        {
          ...old.contents[contentIndex],
          [e.target.name]: e.target.value,
        },
        ...old.contents.slice(contentIndex + 1),
      ],
    }));
  };

  const handleSaveClicked = async () => {
    setErrors({});

    let successMsg = "";

    // validation
    try {
      await getCategoryValidation().validate(category, { abortEarly: false });
    } catch (err) {
      setErrors(
        err.inner.reduce((acc, e) => ({ ...acc, [e.path]: e.message }), {})
      );
      return;
    }

    // post
    if (!activeId) {
      try {
        await postCategory(category);
      } catch (e) {
        console.error(e);
        return;
      }

      successMsg = "Dodana je nova kategorija";
    } else {
      // put
      try {
        await putCategory(activeId, category);
      } catch (e) {
        console.error(e);
        return;
      }

      successMsg = "Kategorija je uređena";
    }

    history.push("/categories");

    addToast(successMsg, { appearance: "success" });
  };

  const handleParentCategoryChange = (c) => {
    setCategory((old) => ({ ...old, parent_id: c ? c.id : null }));
  };

  const handleTemplateChange = (t) => {
    setCategory((old) => ({ ...old, template_id: t ? t.id : null }));
  };

  const handleViewContentChange = (c) => {
    setCategory((old) => ({ ...old, content_view: c ? c.value : null }));
  };

  const handleOrderChange = (e) => {
    setCategory((old) => ({ ...old, order: e.target.value }));
  };

  const handleTinyTXTChange = (lang) => (value) => {
    const contentIndex = category.contents.findIndex(
      (c) => c.language_code === lang
    );

    if (contentIndex === -1) {
      return;
    }

    setCategory((old) => ({
      ...old,
      contents: [
        ...old.contents.slice(0, contentIndex),
        {
          ...old.contents[contentIndex],
          content: value,
        },
        ...old.contents.slice(contentIndex + 1),
      ],
    }));
  };

  const handleAddToSidebarClicked = () => {
    setCategory((old) => ({
      ...old,
      sidebar: [...old.sidebar, { title: "", url: "" }],
    }));
  };

  const handleSidebarChange = (index, name) => (e) => {
    const s = category.sidebar[index];
    setCategory((old) => ({
      ...old,
      sidebar: [
        ...old.sidebar.slice(0, index),
        { ...s, [name]: e.target.value },
        ...old.sidebar.slice(index + 1),
      ],
    }));
  };

  const handleDeleteSidebarClicked = (index) => () => {
    setCategory((old) => ({
      ...old,
      sidebar: [
        ...old.sidebar.slice(0, index),
        ...old.sidebar.slice(index + 1),
      ],
    }));
  };

  const handlePreviewClicked = () => {
    const webUrl = process.env.REACT_APP_WEB_URL;
    window.open(webUrl + category.path, "_blank").focus();
  };

  const handleTextFieldChange = (e) =>
    setCategory((old) => ({ ...old, [e.target.name]: e.target.value }));

  return (
    <>
      <div className="col-lg-8 col-xl-9 mg-b-50">
        <div className="card">
          <div className="card-header">
            <h6 className="mg-b-0">
              Sadržaj <span className="mg-r-20"></span>{" "}
              <button
                onClick={handleSaveClicked}
                type="button"
                className="btn btn-xs btn-outline-secondary"
              >
                <Save style={{ height: "12px", marginTop: "-4px" }} /> Spremi
              </button>
              {!!activeId && (
                <button
                  type="button"
                  className="btn btn-xs btn-outline-secondary"
                >
                  <Trash style={{ height: "12px", marginTop: "-4px" }} /> Obriši
                </button>
              )}
              {!!activeId && (
                <button
                  type="button"
                  className="btn btn-xs btn-outline-secondary ml-1"
                  onClick={handlePreviewClicked}
                >
                  <ExternalLink style={{ height: "12px", marginTop: "-4px" }} />{" "}
                  Pregled
                </button>
              )}
            </h6>
          </div>

          <div className="card-body pd-lg-25">
            <div>
              <ul className="nav nav-line">
                {USED_LANGUAGES.map((lang) => (
                  <li className="nav-item" key={lang}>
                    <a
                      href="#"
                      className={`nav-link ${
                        activeLang === lang ? "active" : ""
                      }`}
                      onClick={handleLangClicked(lang)}
                    >
                      {lang.toUpperCase()}
                    </a>
                  </li>
                ))}
              </ul>

              <div className="tab-content mg-t-20">
                {USED_LANGUAGES.map((lang, index) => (
                  <div
                    className={`tab-pane fade ${
                      activeLang === lang ? "show active" : ""
                    }`}
                    key={lang}
                  >
                    <TextField
                      label="Naziv"
                      name="name"
                      onChange={handleContentChange(lang)}
                      value={_.get(category, `contents[${index}].name`)}
                      hasError={!!_.get(errors, `contents[${index}].name`)}
                      error={_.get(errors, `contents[${index}].name`)}
                    />
                    <TextField
                      label="Slug"
                      name="slug"
                      onChange={handleContentChange(lang)}
                      value={_.get(category, `contents[${index}].slug`)}
                    />
                    <TextField
                      label="Meta opis"
                      name="meta"
                      onChange={handleContentChange(lang)}
                      value={_.get(category, `contents[${index}].meta`)}
                    />
                    <TinyMce
                      label="Tekst"
                      name="content"
                      onEditorChange={handleTinyTXTChange("hr")}
                      value={_.get(category, `contents[0].content`)}
                      hasError={!!_.get(errors, `contents[0].content`)}
                      error={_.get(errors, `contents[0].content`)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-4 col-xl-3 mg-t-10 mg-lg-t-0 mg-b-50">
        <div className="card">
          <div className="card-header">
            <h6 className="mg-b-0">Postavke</h6>
          </div>

          <div className="card-body pd-lg-25">
            <div className="form-group">
              <label>Nadkategorija</label>
              <Select
                options={categories.filter((c) => c.id !== category.id)}
                placeholder="Odaberite kategoriju"
                isSearchable
                isClearable
                value={selectedCategory}
                formatOptionLabel={(c) => c.name}
                getOptionValue={(c) => c.id}
                getOptionLabel={(c) => c.name}
                onChange={handleParentCategoryChange}
              />
            </div>
            <ImageForm
              label="Slika"
              imageUrl={category.image}
              setImageUrl={(image) => setCategory((old) => ({ ...old, image }))}
              // helperText="Preporučena dimenzija: 1920px x 1130px"
            />
            <div className="form-group">
              <label>Predložak</label>
              <Select
                options={templates}
                placeholder="Odaberite predložak"
                isSearchable
                isClearable
                value={selectedTemplate}
                formatOptionLabel={(c) => c.name}
                getOptionValue={(c) => c.id}
                getOptionLabel={(c) => c.name}
                onChange={handleTemplateChange}
              />
            </div>

            <div className="form-group">
              <label>Prikaz sadržaja</label>
              <Select
                options={viewContentOptions}
                placeholder="Odaberite prikaz sadržaja"
                isSearchable
                isClearable
                value={selectedViewContent}
                formatOptionLabel={(c) => c.label}
                getOptionValue={(c) => c.value}
                getOptionLabel={(c) => c.label}
                onChange={handleViewContentChange}
              />
            </div>

            <div className="form-group">
              <label>Redoslijed</label>
              <select
                className="form-control"
                onChange={handleOrderChange}
                value={category.order}
              >
                <option value="desc">Od najnovijeg prema najstarijem</option>
                <option value="asc">Od najstarijeg prema najnovijem</option>
              </select>
            </div>

            <div className="row">
              <div className="form-group col-lg-12">
                <label>Zaključaj kategoriju lozinkom</label>
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="zakljucano"
                    checked={category.is_locked}
                    onChange={(e) =>
                      setCategory((old) => ({
                        ...old,
                        is_locked: e.target.checked,
                      }))
                    }
                  />
                  <label className="custom-control-label" htmlFor="zakljucano">
                    NE / DA
                  </label>
                </div>
                {category.is_locked && (
                  <div className="mg-b-5 mg-t-5">
                    <TextField
                      name="password"
                      onChange={handleTextFieldChange}
                      value={category.password}
                      placeholder="Lozinka"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-8 col-xl-9 mg-b-50">
        <div className="card">
          <div className="card-header">
            <h6 className="mg-b-0">
              Sidebar <span className="mg-r-20"></span>{" "}
              {/* <button
                onClick={handleSaveClicked}
                type="button"
                className="btn btn-xs btn-outline-secondary"
              >
                <Save style={{ height: "12px", marginTop: "-4px" }} /> Spremi
              </button> */}
            </h6>
          </div>

          <div className="card-body pd-lg-25">
            <div>
              {/* <ul className="nav nav-line">
                {USED_LANGUAGES.map((lang) => (
                  <li className="nav-item" key={lang}>
                    <a
                      href="#"
                      className={`nav-link ${
                        activeLang === lang ? "active" : ""
                      }`}
                      onClick={handleLangClicked(lang)}
                    >
                      {lang.toUpperCase()}
                    </a>
                  </li>
                ))}
              </ul> */}

              <div className="tab-content">
                <div className={`tab-pane fade show active`}>
                  {category.sidebar.map((s, i) => (
                    <div className="row">
                      <div className="col-lg-4">
                        <TextField
                          label="Naziv"
                          name="name"
                          onChange={handleSidebarChange(i, "title")}
                          value={s.title}
                        />
                      </div>
                      <div className="col-lg-4">
                        <TextField
                          label="Link"
                          name="link"
                          onChange={handleSidebarChange(i, "url")}
                          value={s.url}
                        />
                      </div>
                      <div className="col-lg-4" style={{ paddingTop: "32px" }}>
                        <button
                          type="button"
                          className="btn btn-xs btn-outline-secondary ml-2"
                          onClick={handleDeleteSidebarClicked(i)}
                        >
                          <Trash
                            style={{ height: "12px", marginTop: "-4px" }}
                          />{" "}
                          Obriši
                        </button>
                      </div>
                    </div>
                  ))}
                  <div className="row">
                    <div className="col-lg-12">
                      <button
                        onClick={handleAddToSidebarClicked}
                        type="button"
                        className="btn btn-xs btn-outline-secondary"
                      >
                        <Plus style={{ height: "12px", marginTop: "-4px" }} />{" "}
                        Dodaj stavku
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoriesForm;
