import moment from "moment";
import { Link } from "react-router-dom";

export const articleColumns = [
  {
    Header: "",
    accessor: "id",
    Cell: () => (
      <div className="custom-control custom-checkbox">
        <input type="checkbox" className="custom-control-input" />
        <label className="custom-control-label" />
      </div>
    ),
    width: 50,
  },
  {
    Header: "ID",
    accessor: (row) => row.id,
    width: 100,
  },
  
  {
    Header: "Naslov",
    Cell: ({ row }) => {
      return (
        <Link to={`/articles/${row.original.id}/edit`}>
          {row.original.title}
        </Link>
      );
    },
  },
  {
    Header: "Kategorije",
    Cell: ({ row }) => row.original.categories.map((c) => c.name).join(", "),
    width: 200,
  },
  {
    Header: "Datum i vrijeme objave",
    accessor: "active_from",
    Cell: ({ row }) =>
      row.original.active_from
        ? moment(row.original.active_from).format("DD.MM.YYYY. HH:mm")
        : "",
  },
  {
    Header: "Čitanja",
    accessor: (row) => row.hits,
    width: 70,
  },
  {
    Header: "Objavio",
    accessor: "user.username",
  },
  {
    Header: "Objavljen",
    accessor: () => "",
    width: 70,
  },
];
